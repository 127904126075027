import React from 'react'
import { Switch, BrowserRouter, Route, Redirect, } from "react-router-dom";

import PageLogin from "../modules/login/pages/home";
import LoginPlus from "../modules/login/pages/LoginPlus";
import PageRegister from "../modules/login/pages/register";
import PagePassword from "../modules/login/pages/password";
import PageDashboard from "../modules/dashboard/pages/home";
import PageIntegrations from "../modules/integrations/pages/home";
import PageCode from "../modules/integrations/pages/code";
import PageAssinatura from "../modules/assinatura/pages/home";
import PageAjuda from "../modules/ajuda/pages/home";
import InjectAxiosInterceptors from '../services/InjectAxiosInterceptors';
import PageAccount from "../modules/account/pages/home";
import PageAdmin from "../modules/admin/pages/home";
import PageThema from "../modules/thema/pages/home";
import PageAffiliate from "../modules/login/pages/affiliate";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PageWix from "../modules/integrations/pages/wix-integration";
import { useDispatch } from 'react-redux';

const Routes = () => {



    return (
        <BrowserRouter>  
            <InjectAxiosInterceptors /> 
            <Switch>
                <Route exact path="/eplus/auth/link-magico"> <LoginPlus /> </Route>
                <Route exact path="/login"> <PageLogin /> </Route>
                <Route exact path="/cadastro"> <PageRegister /> </Route>
                <Route exact path="/senha"> <PagePassword /> </Route>
                <Route exact path="/cadastro/afiliado"> <PageAffiliate /> </Route>
               <PrivateRoute exact path="/" > <Redirect to={"/personalizar"} /> </PrivateRoute> 
                <PrivateRoute exact path="/pagamentos" > <PageAssinatura /> </PrivateRoute>
                <PrivateRoute exact path="/ajuda" > <PageAjuda /> </PrivateRoute>
                <PrivateRoute exact path="/conta" > <PageAccount /> </PrivateRoute>
                <PrivateRoute exact path="/integracoes" > <PageIntegrations /> </PrivateRoute>
                <PrivateRoute exact path="/admin" > <PageAdmin /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar" > <PageThema /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/tema" > <PageThema /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/botao" > <PageThema /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/caixa" > <PageThema /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/icone" > <PageThema /> </PrivateRoute>

                {/* <PrivateRoute> <PageDashboard /> </PrivateRoute> */}
                {/* <PrivateRoute exact path="/preview" > <PagePreview /> </PrivateRoute> */}

                <PrivateRoute exact path="/integracoes/:type" > <PageCode /> </PrivateRoute>
                <PrivateRoute exact path="/wix"> <PageWix /> </PrivateRoute>

            </Switch>
        </BrowserRouter>
    )
}

export default Routes



const PrivateRoute = ({ children, ...rest }) => {

    const dispatch = useDispatch()
    const urlParams = new URLSearchParams(window.location.search);

    console.log("rest", rest)

    if(rest.path == "/"){

    }

    if (rest.path == "/wix") {
        let tokenCode = new URLSearchParams(window.location.search).get("token")
        let instanceCode = new URLSearchParams(window.location.search).get("instance")
        if (tokenCode) localStorage.setItem("wix@code", tokenCode)
        if (instanceCode) localStorage.setItem("wix@instanceCode", instanceCode)
    }

    console.log("rest.pathname", rest.location.pathname == "/integracoes/nuvemshop")
    console.log("typeof urlParams.get", typeof urlParams.get("code") == "string")
    console.log("urlParams.get)", urlParams.get("code"))
    console.log("rest.pathname", rest.location.pathname)

    if (rest.location.pathname == "/integracoes/nuvemshop" && typeof urlParams.get("code") == "string" && !localStorage.getItem("depoimentos@login")) {
        console.log("entrei2")
        dispatch({ type: "SAGA_GET_TOKEN_NUVEMSHOP", payload: { code: urlParams.get("code") } })
        //localStorage.setItem('nuvemshop@code', urlParams.get("code"))
        //if (typeof urlParams.get("code") == "string") {
        //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))
        //console.log("entrei2")
        //window.location.href = urlParams.get("url");
        //window.open(urlParams.get("url"))
        //}
    }


    if (typeof urlParams.get("ref") == "string") {
        //console.log("entrei")
        localStorage.setItem('depoimentos@affiliate', urlParams.get("ref"))
        if (typeof urlParams.get("url") == "string") {
            //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))
            //console.log("entrei2")
            window.location.href = urlParams.get("url");
            //window.open(urlParams.get("url"))
        }
    } else {
        return (
            <Route {...rest}
                render={({ location }) => isAuthenticated() ? (children) : (<Redirect to={{ pathname: "/login", state: { from: location } }} />)}
            />)
    }


}

const isAuthenticated = () => {
    //const queryString = window.location.search;

    // console.log("Route: =>", typeof urlParams.get("url"))
    // if (typeof urlParams.get("ref") == "string") {
    //     //console.log("entrei")
    //     localStorage.setItem('depoimentos@affiliate', urlParams.get("ref"))
    //     if (typeof urlParams.get("url") == "string") {
    //         //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))
    //         //console.log("entrei2")
    //         window.location.href = urlParams.get("url");
    //         //window.open(urlParams.get("url"))
    //     }
    // } else {
    return localStorage.getItem('depoimentos@login') || localStorage.getItem('depoimentosAdmin@login') ? true : false
    // }

}




