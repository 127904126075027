import React, { useState, useEffect } from 'react'
import { BoxPage, BoxSlider, ButtonHelp, ButtonReset, ButtonSave, CheckCustom, Container, OptionPage, SelectCustom, Title, } from './styled'
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from 'react-redux';
import { BsCheck } from "react-icons/bs";
import IconReset from "../../../../assets/imgs/icon-reset.svg";
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom'
import { BarConfigTitle, BoxFont, BoxTitle, CounterItemsCircle, ItemBar, ItemColor, ItemColorCustom, TextAreaCustom } from '../bar-config-avancado/styled';

import IconTextLeft from "../../../../assets/imgs/text-left.svg";
import IconTextRight from "../../../../assets/imgs/text-right.svg";
import IconTextCenter from "../../../../assets/imgs/text-center.svg";
import IconTextBold from "../../../../assets/imgs/text-bold.svg";
import IconTextUnderline from "../../../../assets/imgs/text-underline.svg";
import IconTextItalic from "../../../../assets/imgs/text-italic.svg";
import { ItemLine, ItemLineColor } from '../bar-config-button/styled';
import { getTranslation } from '../../../../translations';
import { InputTextButton } from '../bar-config-icon/styled';


const BarConfig = () => {

    const dispatch = useDispatch()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const history = useHistory()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const [clickXpath, setClickXpath] = useState(0)

    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                desktop: configControl.desktop ? 1 : 0,
                mobile: configControl.mobile ? 1 : 0,
                size_img: configControl.size_img,
                days_time: configControl.radioTime == "week" ? configControl.qtdTime * 7 : configControl.qtdTime,
                slider_scroll: configControl.slider_scroll ? 1 : 0,
                homepage: configControl.homepage ? 1 : 0,
                cart_page: configControl.cart_page ? 1 : 0,
                product_page: configControl.product_page ? 1 : 0,
                collection_page: configControl.collection_page ? 1 : 0,
                slider_products: configControl.slider_products ? 1 : 0,
                slider_auto: configControl.slider_auto ? 1 : 0,
                slider_paginate: configControl.slider_paginate ? 1 : 0,
                font: configControl.font,
                title: configControl.title,
                color_font_title: configControl.color_font_title,
                title_italic: configControl.title_italic ? 1 : 0,
                title_underline: configControl.title_underline ? 1 : 0,
                title_bolt: configControl.title_bolt ? 1 : 0,
                title_align: configControl.title_align,
                title_font_size: configControl.title_font_size,
                color_scroll: configControl.color_scroll,
                color_widget: configControl.color_widget,

                widget_max_width: configControl.widget_max_width,

                color_line: configControl.color_line,

                color_scroll_background: configControl.color_scroll_background,
                list_type_icon: configControl.list_type_icon,
                list_color_icon: configControl.list_color_icon,
                list_color_background_icon: configControl.list_color_background_icon,

                widget_type: configControl.widget_type,
                widget_layout: configControl.widget_layout,
                widget_card: configControl.widget_card,
                widget_card_name_visible: configControl.widget_card_name_visible,
                widget_card_image_size: configControl.widget_card_image_size,
                visible_add_cart: configControl.visible_add_cart,

                widget_color_aba: configControl.widget_color_aba,
                widget_color_aba_border: configControl.widget_color_aba_border,
                widget_color_aba_text: configControl.widget_color_aba_text,

                xpath_widget: configControl.xpath_widget,
                xpath_widget_home: configControl.xpath_widget_home,
                xpath_widget_products: configControl.xpath_widget_products,

                widget_color_card_button_text: configControl.widget_color_card_button_text,
                widget_color_card_button_background: configControl.widget_color_card_button_background,
                widget_color_card_button_border: configControl.widget_color_card_button_border

            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data?.id,
            size_img: "150 x 250",
            slider_scroll: 1,
            slider_paginate: 1,
            desktop: 1,
            mobile: 1,
            days_time: 0,
            homepage: 1,
            cart_page: 1,
            product_page: 1,
            collection_page: 1,
            slider_products: 1,
            slider_auto: 1,

            title: "Favoritos e Histórico",
            color_scroll: "#000000",
            color_widget: "#ffffff",
            color_font_title: "#2E2E2E",
            title_italic: 0,
            title_underline: 0,
            title_bolt: 0,
            title_align: "left",
            title_font_size: "24px",
            font: "Rubik",

            color_scroll_background: "#222222",
            list_type_icon: "trash",
            list_color_icon: "#ffffff",
            list_color_background_icon: "#414141",

            color_line: "#dadada",
            color_price: "#df3b3b",
            color_name: "#000000",

            widget_card: "modelo01",
            widget_layout: "modelo01",
            widget_type: "favorites-historic",
            widget_card_name_visible: 0,
            widget_card_image_size: "80x80",

            widget_color_aba: "#EEEEEE",
            widget_color_aba_border: "#222222",
            widget_color_aba_text: "#222222",

            widget_color_card_button_text: "#222222",
            widget_color_card_button_background: "#ffffff",
            widget_color_card_button_border: "#808080"


        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })

    }


    return (
        <Container>

            <Title>
                <span onClick={() => { history.push("/personalizar") }}>
                    <IoIosArrowBack />
                </span>
                <label>
                    {translation.thema.barraListaFavoritos.titulo}
                </label>
            </Title>

            <ItemLine>
                <label> {translation.thema.barraListaFavoritos.funcao}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }}
                    onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_type: e.target.value } }) }}
                >
                    <option value="favorites-historic" selected={configControl?.widget_type == "favorites-historic" ? true : false}> {translation.thema.barraListaFavoritos.favoritosHistorico}</option>
                    <option value="favorites" selected={configControl?.widget_type == "favorites" ? true : false} > {translation.thema.barraListaFavoritos.somenteFavoritos} </option>
                    <option value="historic" selected={configControl?.widget_type == "historic" ? true : false} > {translation.thema.barraListaFavoritos.somenteHistorico} </option>
                </SelectCustom>
            </ItemLine>

            <ItemLine>
                <label> {translation.thema.barraListaFavoritos.tamanhoImagem}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }}
                    onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_card_image_size: e.target.value } }) }}
                >
                    <option value={"80x80"} selected={configControl?.widget_card_image_size == "80x80" ? true : false} >80 x 80 </option>
                    <option value={"100x100"} selected={configControl?.widget_card_image_size == "100x100" ? true : false} >100 x 100 </option>
                    <option value={"150x150"} selected={configControl?.widget_card_image_size == "150x150" ? true : false} >150 x 150 </option>
                </SelectCustom>
            </ItemLine>


            <ItemLine>
                <label> {translation.thema.barraListaFavoritos.nome}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }}
                    onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_card_name_visible: e.target.value } }) }}
                >
                    <option value={0} selected={configControl?.widget_card_name_visible == 0 ? true : false} > {translation.thema.barraListaFavoritos.naoMostrar} </option>
                    <option value={1} selected={configControl?.widget_card_name_visible == 1 ? true : false} >Mostrar </option>
                </SelectCustom>
            </ItemLine>

            <ItemLine>
                <label> {translation.thema.barraListaFavoritos.botaoCarrinho}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }}
                    onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_add_cart: e.target.value } }) }}
                >
                    <option value={0} selected={configControl?.visible_add_cart == 0 ? true : false} > {translation.thema.barraListaFavoritos.naoMostrar} </option>
                    <option value={1} selected={configControl?.visible_add_cart == 1 ? true : false} >Mostrar </option>
                </SelectCustom>
            </ItemLine>

            <ItemLine>
                <label style={{ fontSize: "9px", }}> {`${translation.thema.barraListaFavoritos.maximoLargura}: (px)`}</label>
                <InputTextButton type='text' placeholder='1200' value={configControl?.widget_max_width} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_max_width: e.target.value } }) }} />
            </ItemLine>


            <ItemLine style={{ gap: "10px" }}>
                <label> {translation.thema.barraListaFavoritos.itemCores}</label>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.fundoWidget}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_widget} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_widget: e.target.value } })} />
                            <label>{configControl.color_widget?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.setaRolagem}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_scroll_background} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_scroll_background: e.target.value } })} />
                            <label>{configControl.color_scroll_background?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.setaHover}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_scroll} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_scroll: e.target.value } })} />
                            <label>{configControl.color_scroll?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.linha}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_line} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_line: e.target.value } })} />
                            <label>{configControl.color_line?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                {/* <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{"Preço"}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_price} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_price: e.target.value } })} />
                            <label>{configControl.color_price?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor> */}

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.nome}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_name} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_name: e.target.value } })} />
                            <label>{configControl.color_name?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>


                {/* <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.fundoSeta}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.color_scroll_background} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_scroll_background: e.target.value } })} />
                            <label>{configControl.color_scroll_background?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor> */}

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.icone}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.list_color_icon} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { list_color_icon: e.target.value } })} />
                            <label>{configControl.list_color_icon?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.fundoIcone}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.list_color_background_icon} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { list_color_background_icon: e.target.value } })} />
                            <label>{configControl.list_color_background_icon?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.menu}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_aba} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_aba: e.target.value } })} />
                            <label>{configControl.widget_color_aba?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.textoMenu}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_aba_text} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_aba_text: e.target.value } })} />
                            <label>{configControl.widget_color_aba_text?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.bordaMenu}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_aba_border} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_aba_border: e.target.value } })} />
                            <label>{configControl.widget_color_aba_border?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.fundoBotao}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_card_button_background} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_card_button_background: e.target.value } })} />
                            <label>{configControl.widget_color_card_button_background?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.textoBotao}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_card_button_text} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_card_button_text: e.target.value } })} />
                            <label>{configControl.widget_color_card_button_text?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>

                <ItemLineColor>
                    <label style={{ fontSize: "12px" }}>{translation.thema.barraListaFavoritos.bordaBotao}</label>
                    <ItemColor>
                        <span>
                            <input type={"color"} value={configControl.widget_color_card_button_border} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_color_card_button_border: e.target.value } })} />
                            <label>{configControl.widget_color_card_button_border?.toUpperCase()}</label>
                        </span>
                    </ItemColor>
                </ItemLineColor>
            </ItemLine>

            <ItemLine>
                <label>{translation.thema.barraListaFavoritos.itemIcone}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }}
                    onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { list_type_icon: e.target.value } }) }}
                >
                    <option value="heart" selected={configControl.list_type_icon == "heart" ? true : false}>{translation.thema.barraListaFavoritos.coracao}</option>
                    <option value="gift" selected={configControl.list_type_icon == "gift" ? true : false} >{translation.thema.barraListaFavoritos.presente} </option>
                    <option value="trash" selected={configControl.list_type_icon == "trash" ? true : false} >{translation.thema.barraListaFavoritos.lixeira} </option>
                </SelectCustom>
            </ItemLine>

            <BoxPage>
                <label>{translation.thema.barraListaFavoritos.itemPaginaExibicao}</label>
                <div>
                    <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { product_page: !configControl.product_page } }) }} >
                        <label>{translation.thema.barraListaFavoritos.paginaProduto}</label>
                        <CheckCustom active={configControl.product_page}>
                            <span>
                                <BsCheck color={"#fff"} size={10} />
                            </span>
                        </CheckCustom>
                    </OptionPage>

                    <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homepage: !configControl.homepage } }) }} >
                        <label>Página Inicial</label>
                        <CheckCustom active={configControl.homepage}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage>

                    {/* <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { cart_page: !configControl.cart_page } }) }} >
                        <label>Página do Carrinho</label>
                        <CheckCustom active={configControl.cart_page}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage> */}
                </div>
                <div style={{ marginTop: "5px" }}>
                    <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { collection_page: !configControl.collection_page } }) }} >
                        <label>{translation.thema.barraListaFavoritos.paginaColecao}</label>
                        <CheckCustom active={configControl.collection_page}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage>

                    {/* <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homepage: !configControl.homepage } }) }} >
                        <label>Página Categorias</label>
                        <CheckCustom active={configControl.homepage}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage> */}



                    {/* <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { collection_page: !configControl.collection_page } }) }} >
                        <label>Página da Coleção</label>
                        <CheckCustom active={configControl.collection_page}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage> */}
                </div>
            </BoxPage>

            <BoxSlider>
                <label>{translation.thema.barraListaFavoritos.itemPaginaSlider} </label>
                <div>
                    <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { slider_scroll: !configControl.slider_scroll } }) }} >
                        <label>{translation.thema.barraListaFavoritos.setaDeRolagem}</label>
                        <CheckCustom active={configControl.slider_scroll}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage>

                    <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { slider_auto: !configControl.slider_auto } }) }} >
                        <label>{translation.thema.barraListaFavoritos.setaAutomatica}</label>
                        <CheckCustom active={configControl.slider_auto}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage>
                </div>
                {/* <div style={{ marginTop: "5px" }}> */}
                {/* <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { slider_products: !configControl.slider_products } }) }} >
                        <label>Loop dos Produtos</label>
                        <CheckCustom active={configControl.slider_products}>
                            <span>
                                <BsCheck color={"#fff"} size={10} />
                            </span>
                        </CheckCustom>
                    </OptionPage> */}

                {/* <OptionPage onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { slider_paginate: !configControl.slider_paginate } }) }} >
                        <label>{translation.thema.barraListaFavoritos.mostrarQuantidade}</label>
                        <CheckCustom active={configControl.slider_paginate}>
                            <BsCheck color={"#fff"} size={10} />
                        </CheckCustom>
                    </OptionPage> */}
                {/* </div> */}
            </BoxSlider>

            <BoxTitle>
                <label>{translation.thema.barraListaFavoritos.itemTituloSecao}:</label>
                <div style={{ position: "relative" }}>
                    <TextAreaCustom
                        maxLength="40"
                        rows={3}
                        placeholder={"Produtos vistos recentemente"}
                        name={"title"}
                        value={configControl.title}
                        onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { title: e.target.value } })}
                    />
                    <BarConfigTitle>
                        <select onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_font_size: e.target.value } }) }}>
                            <option value={"9px"} selected={configControl.title_font_size == "9px" ? true : false}>9px</option>
                            <option value={"10px"} selected={configControl.title_font_size == "10px" ? true : false}>10px</option>
                            <option value={"11px"} selected={configControl.title_font_size == "11px" ? true : false}>11px</option>
                            <option value={"12px"} selected={configControl.title_font_size == "12px" ? true : false}>12px</option>
                            <option value={"14px"} selected={configControl.title_font_size == "14px" ? true : false}>14px</option>
                            <option value={"16px"} selected={configControl.title_font_size == "16px" ? true : false}>16px</option>
                            <option value={"18px"} selected={configControl.title_font_size == "18px" ? true : false}>18px</option>
                            <option value={"20px"} selected={configControl.title_font_size == "20px" ? true : false}>20px</option>
                            <option value={"22px"} selected={configControl.title_font_size == "22px" ? true : false}>22px</option>
                            <option value={"24px"} selected={configControl.title_font_size == "24px" ? true : false}>24px</option>
                            <option value={"26px"} selected={configControl.title_font_size == "26px" ? true : false}>26px</option>
                            <option value={"28px"} selected={configControl.title_font_size == "28px" ? true : false}>28px</option>
                            <option value={"30px"} selected={configControl.title_font_size == "30px" ? true : false}>30px</option>
                            <option value={"32px"} selected={configControl.title_font_size == "32px" ? true : false}>32px</option>
                        </select>

                        <ItemColorCustom color={configControl.color_font_title}>
                            <label for={"testes"}>A</label>
                            <div />
                            <input id={"testes"} type={"color"} value={configControl.color_font_title} onChange={(e) => dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_font_title: e.target.value } })} />
                        </ItemColorCustom>

                        {/* <ItemBar active={configControl.title_align == "left" ? true : false}>
                            <img src={IconTextLeft} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_align: "left" } }) }} />
                        </ItemBar>

                        <ItemBar active={configControl.title_align == "center" ? true : false}>
                            <img src={IconTextCenter} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_align: "center" } }) }} />
                        </ItemBar>

                        <ItemBar active={configControl.title_align == "right" ? true : false}>
                            <img src={IconTextRight} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_align: "right" } }) }} />
                        </ItemBar> */}

                        <ItemBar active={configControl.title_bolt}>
                            <img src={IconTextBold} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_bolt: !configControl.title_bolt } }) }} />
                        </ItemBar>

                        <ItemBar active={configControl.title_italic}>
                            <img src={IconTextItalic} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_italic: !configControl.title_italic } }) }} />
                        </ItemBar>

                        {/* <ItemBar active={configControl.title_underline}>
                            <img src={IconTextUnderline} onClick={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { title_underline: !configControl.title_underline } }) }} />
                        </ItemBar> */}

                    </BarConfigTitle>
                    <CounterItemsCircle porc={`${Math.round(configControl.title?.length / 40 * 100)}%`}>
                        <label>{40 - configControl.title?.length}</label>
                    </CounterItemsCircle>
                </div>
            </BoxTitle>

            <BoxFont style={{ width: "100%", }}>
                <label>{translation.thema.barraListaFavoritos.itemEscolherFonte}</label>
                <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font: e.target.value } }) }}>
                    <option value="Poppins" selected={configControl.font == "Poppins" ? true : false}>Poppins</option>
                    <option value="Courier" selected={configControl.font == "Courier" ? true : false} >Courier</option>
                    <option value="Times" selected={configControl.font == "Times" ? true : false} >Times</option>
                    <option value="Rubik" selected={configControl.font == "Rubik" ? true : false} >Rubik</option>
                </SelectCustom>
            </BoxFont>

            {clickXpath > 9 &&
                <ItemLine style={{ gap: "2px", marginTop: "40px" }}>
                    <label style={{ fontSize: "9px" }}> Xpath</label>
                    <InputTextButton type='text' placeholder='Posição' value={configControl.xpath_widget} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { xpath_widget: e.target.value } }) }} />
                </ItemLine>}

            {clickXpath > 9 &&
                <ItemLine style={{ gap: "2px", marginTop: "40px" }}>
                    <label style={{ fontSize: "9px" }}> Xpath home</label>
                    <InputTextButton type='text' placeholder='Posição' value={configControl.xpath_widget_home} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { xpath_widget_home: e.target.value } }) }} />
                </ItemLine>}

            {clickXpath > 9 &&
                <ItemLine style={{ gap: "2px", marginTop: "40px" }}>
                    <label style={{ fontSize: "9px" }}> Xpath produtos</label>
                    <InputTextButton type='text' placeholder='Posição' value={configControl.xpath_widget_products} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { xpath_widget_products: e.target.value } }) }} />
                </ItemLine>}

            <ButtonHelp onClick={() => { setClickXpath(clickXpath + 1) }}>
                <img src={IconBossVideo} />
                <label>{translation.thema.acoes.ajuda}</label>
                <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
            </ButtonHelp>

            <ButtonSave onClick={() => saveTheme()}>
                {translation.thema.acoes.salvar}
            </ButtonSave>

            <ButtonReset onClick={() => getDefaultTheme()}>
                <label>{translation.thema.acoes.restaurarPadrao}</label>
                <img src={IconReset} />
            </ButtonReset>

        </Container>
    )
}

export default BarConfig