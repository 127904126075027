import React, { useState } from 'react'
import { Container, Icon, Name, Price, SpaceControl } from './styled'
import { useSelector } from 'react-redux'
import { AiFillGift, AiFillHeart } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'

const Card04 = (props) => {

    const config = useSelector(state => state.reducerControlConfig)
    const [houver, setHouver] = useState(false)

    const getIcon = () => {
        switch (config.list_type_icon) {
            case "heart":
                return <AiFillHeart color={config.list_color_icon} size={12} />
            case "gift":
                return <AiFillGift color={config.list_color_icon} size={12} />
            case "trash":
                return <FaTrash color={config.list_color_icon} size={10} />
            default:
                return <FaTrash color={config.list_color_icon} size={10} />
        }
    }

    return (
        <Container onMouseOver={() => { setHouver(true) }} onMouseOut={() => { setHouver(false) }} >
            <img src={props.data.url} />
            <Icon visible={true} background={config.list_color_background_icon} >
                {getIcon()}
            </Icon>
        </Container>
    )
}

export default Card04