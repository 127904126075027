import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100px;
    max-width: 100px;
    //background-color: gray;
    flex-direction: column;
    align-items: center;
    //gap: 10px;
    padding: 20px 0px;
    position: relative;
    cursor: pointer;

    img{
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
`

export const Name = styled.label`
    display: flex;
    font-size: 14px;
    color: ${props => props.color};
    cursor: pointer;
    //font-family: 'Rubik', sans-serif;
    font-family: ${props => props.font}, sans-serif;

    :hover{
        text-decoration: underline;
        color:  #0E8CE4 ;
    }
    //background-color: green;
`

export const Price = styled.label`
    display: flex;
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.color};
    //background-color: purple;
    //font-family: 'Rubik', sans-serif;
    font-family: ${props => props.font}, sans-serif;
`

export const SpaceControl = styled.label`
    display: flex;
    flex: 1;
`

export const Icon = styled.div`
    display: ${props => props.visible ? "flex" : "none"};
    width: 20px;
    height: 20px;
    background-color: ${props => props.background};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
`