import logoAnimation from "../../assets/animation/super-favoritos-loading.gif";
import React from 'react'
import {ContainerLoading, Loader01 } from './styled'

const LoadingLogoLily = () => {
    return (
        <ContainerLoading>
            <Loader01 />
            <img src={logoAnimation} />
            <span />
        </ContainerLoading>
    )
}

export default LoadingLogoLily