import styled from "styled-components";

export const FormCustom = styled.form`
    
display: flex;
flex-direction: column;
//background-color: white;
border-radius: 10px;
padding: 0px 0px 20px 0px;
//background-color: green;
//width: calc(100% - 40px) ;
position: relative ;
`;

export const AlertSave = styled.div`
    background-color: #242527 ;
    display: flex ;
    width: 700px ;
    bottom: 100px;
    position: fixed ;
    height: 60px ;
    align-items: center ;
    padding: 0px 10px ;
    border-radius: 4px ;
    gap: 10px;

    img{
        width: 44px ;
        height: 44px ;
    }

    span{
        display: flex ;
        flex: 1;
        //background-color: yellow ;
        justify-content: space-between ;

        label{
            font-size: 14px ;
            display: flex ;
            align-items: center ;
            font-size: 14px ;
            color: #fff;

        }

        div{
            display: flex ;
            gap: 10px;
            align-items: center ;

            label{
                font-size: 14px ;
                height: 35px ;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                display: flex ;
                align-items: center ;
                padding: 0px 10px ;
                color: white ;
                font-size: 14px  !important;
                cursor: pointer;
            }

            span{
                background-color: #00803B ;
                border: none ;
                outline: none ;
                cursor: pointer;
                height: 35px ;
                border-radius: 4px ;
                width: 90px ;
                color: white ;
                font-size: 14px ;
                display: flex ;
                align-items: center ;
                justify-content: center ;
            }
        }
    }
`

export const EditCustom = styled.label`
    
    display: flex;
    
    background-color: #292A2D;
    bottom: 100px;
    //left: 50%;
    //margin-left: -75px;
    width: 120px ;
    height: 35px ;
    justify-content: center ;
    border-radius: 20px ;
    align-items: center ;
    font-size: 14px ;
    //top: 450px;
    cursor: pointer;
    position: fixed;
    color: #fff ;
    gap: 10px;

    img{
        width: 20px;
        height: 20px;
    }

`;

export const BoxForm = styled.div`
background-color: white;
display: flex;
flex-direction: column;
padding: 5px;
border-radius: 10px;
//margin-bottom: 20px;
height: 100% ;
align-items: center !important ;
/* height: 400px ;
overflow: auto;
    position: fixed ; */
    //background-color: yellow;
`;

export const BoxFormTitle = styled.label`
display: flex;
height: 50px;
align-items: center;
font-size: 18px;
font-weight: 500;
color: #3F4254;

span{
    background-color: #ffcc00;
    color: #5e0a07;
    padding: 0px 10px;
    margin: 0px 10px;
    display: flex;
    border-radius: 10px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}
`;

export const InputCustomItemColor = styled.div`

background-color: #F5F8FA;
display: flex;
height: 40px;
width: 50px;
border-radius: 5px 0px 0px 5px;
justify-content: center;
align-items: center;
border-width: 1px 0px 1px 1px;
border-color: #EFF2F5;
border-style: solid;



input[type=color] {
    width: 20px;
    height: 20px; 
    border-radius: 50%;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    border: none;
    cursor: pointer;
    font-size: 11px ;   
    border: 1px solid #80808044;
}

input[type=color]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    font-size: 11px ;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    font-size: 11px ;
}

`;

export const SelectCustom = styled.select`
    display: flex;
    background-color: #f5f8fa;
    height: 40px;
    width: 150px !important;
    font-weight: bold;
    color: #5E6278;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 5px;
    outline: none;
    font-size: 11px ;

    @media(min-width: 480px) {
        width: 280px;
    }
`

export const InputCustomItem = styled.div`

display: flex;
flex-direction: column;
margin-bottom: 15px;

//background-color: red;

label{
    font-size: 12px;
    color: #617182;
    display: flex ;

    //background-color: yellow ;
    align-items: center ;

    svg{
        margin-left: 5px ;
        margin-top: -2px ;
    }
}

span{ 
    display: flex;
    align-items: center;
    width: 150px !important;

    @media(min-width: 480px) {
        width: 280px;
    }
    
    input{
        width: 100%;
        background-color: #f5f8fa;
        height: 40px;
        outline: none;
        border: none;
        padding: 0px 10px 0px 0px;
        text-transform: uppercase;
        font-weight: bold;
        color: #5E6278;
        border-width: 1px 1px 1px 0px;
        border-color: #EFF2F5;
        border-style: solid;
        border-radius: 0px 5px 5px 0px;
        font-size: 11px ;
    }
    input[type=number]::-webkit-inner-spin-button {
        opacity: 1
    }
    abbr{
        display: flex;
        svg{
            margin-left: 10px;
        }
    }
    div{
        display: flex;
        svg{
            margin-left: 10px;
            cursor: help;
        }
    }
}    
`;

export const Tooltips = styled.div`
    position: relative;  
    //background-color: yellow ;

    :hover div{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
    }

    div{
        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        //transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        label{
            font-size: 10px;
            color: white;
        }
  

        :after{
       
            content: '';
            position: absolute;
            top: 100%;
            left: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        }
    }



`;

export const Box = styled.div`
    //background-color: yellow;
    display: flex;
    //background-color: purple ;
    min-width: 100% ;

    @media(max-width: 720px) {
        //flex-direction: column ;
    }
`

export const ErrorMessage = styled.label`
    //background-color: yellow;
    display: flex;
    margin-top: -15px;
    margin-bottom: 5px;
    font-size: 12px;
    color: red;
`;

export const BoxActions = styled.div`
    display: flex;
    //background-color: red ;
`;

export const ButtonPreview = styled.button`
    width: 75px;
    height: 35px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    background-color: #ffcc00;
    color: #5e0a07;
`;

export const ButtonSave = styled.button`
    background-color: #19B674;
    width: 60px;
    height: 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 0px;
    font-size: 12px ;
`;

export const ButtonDefault = styled.button`
    background-color: #191C31;
    padding:0px 10px;
    height: 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 10px;
    font-size: 12px ;
    
`;