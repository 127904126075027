import styled from "styled-components";

export const Box = styled.div`
  background-color:  ${props => props.backgroundColor};
  padding: 20px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  min-height: 630px;
`

export const Count = styled.label`
  display: flex;
  background-color: ${props => props.background};
  justify-content: center;
  align-items: center;
  width:${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  position: absolute;
  top: ${props => `-${(props.size * 80) / 100}px`};
  right: ${props => `-${(props.size * 80) / 100}px`};
  border-radius: 50%;
  font-size: ${props => props.fontSize};
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
`

export const BoxPreview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  //width: 400px;
  //max-width: calc(100vw - 60px);
  //background-color: blue;
  //background-color: red;
  width: 100%;

  @media(max-width: 768px) {
    max-width: calc(100vw - 60px);
  }
  

`

export const Container = styled.div`

  display: flex ;
  flex: 100% ;
  //margin-left: 10px ;
  flex-direction:  column;
  padding: 40px;
  border-radius: 5px;
  /* margin-left: 350px ; */
  font-family: ${props => props.font} ; 

  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
  background-size: 60%;

  height: 100% ;

  //background-color: yellow ;
  //width: calc(100vw - 30px) ;
  width: 100% ;

  @media(max-width: 1100px) {
     //background-color: red ;
  }

`

export const Tela = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  //background-color: #ffffff;
  height: 450px;
  flex-direction: column;
  
`
export const TelaContents = styled.div`
  display: flex;
  background-color: #fff;
  min-width: 100%;
  min-height: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  border-radius: 20px 20px 0px 0px;
`

export const TelaPreview = styled.div`
  display: flex;
  background-color: #F3F3F3;
  height: 100%;
  min-height: 336px;
  //min-width: calc(100% - 60px);
  margin: 26px 30px;
  border-radius: 0px;
  align-items: flex-end;
  flex-direction: column;
`

export const Space = styled.div`
  display: flex;
  min-height: 58px;
  min-width: 100%;
  background-color: #fff;
  border-top: 2px solid #F3F3F3;
  margin: 0px;
  border-radius: 0px 0px 20px 20px;
  border-bottom: 1px solid #F3F3F3;
`

export const BoxImg = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: center;
`

export const ContainerPreview = styled.div`
  display: flex;
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  height: max-content;
  width: 100%;
  background-color: yellow;
  flex: 1;
`

export const PreviewTitle = styled.div`
  display: flex;
  //background-color: violet;
  height: 40px;
  align-items: center;
  justify-content: ${props => props.align};

  label{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    color: ${props => props.colorTitle};
    font-weight: ${props => props.bold ? "bold" : "initial"};
    text-decoration:  ${props => props.underline ? "underline" : "initial"};
    font-style: ${props => props.italic ? "italic" : "initial"};
    font-size: ${props => props.fontSize};
    font-family: ${props => props.font};

    span{
      font-size: 8px;
      color: #0047FF;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
`

export const PreviewBox = styled.div`
  display: flex;
  //background-color: green;
  //height: 125px;
  align-items: center;
  //margin: 10px 0px ;
  margin: ${props => props.visible ? "10px 0px" : "10px 20px"};
  padding-bottom: ${props => props.visible ? "10px" : "10px"};
`

export const Voltar = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: violet;
  margin: 0px 20px;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }

`

export const Proximo = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  margin: 0px 20px;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }
  
`

export const BoxCards = styled.div`
  display: flex;
  //background-color: violet;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  span{
    background: #E0E0E0;
    border-radius: 2px;
    min-width: 60px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    label{
      font-size: 9px;
      font-weight: bold;
    }
  }

`

export const PreviewPaginate = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  label{
    font-size: 8px;
  }

`
export const BarMenuStore = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: #c4c4c4;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  width: 100%;

  label{
    color: #fff;
  
  }

  span{
    display: flex;
    width: 100%;
    background-color: #fff;
    height: 35px;
    border-radius: 4px;
    align-items: center;
    padding: 0px 10px;

    label{
      color: #00000088;
      font-size: 14px;
    }
  }

`

export const ContainerStore = styled.div`
  display: flex;
  height: 100%;
  //background-color: red;
  width: 100%;
  position: relative;


`
export const IconBoxStore = styled.div`
  display: flex;
  position: absolute;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  background-color: ${props => props.background};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  //right: 10px;
  //top: calc(50% - 25px);

  div{
    position: relative;
    min-width: 1px;
    min-height: 1px;

    ::before, ::after{
    content: "";
    //display: block;
    display: none;
    position: absolute;
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: -30px;
    border: 2px solid #46459B44;
    border-radius: 50%;
    animation: animatePulse 0.8s linear infinite;

      @keyframes animatePulse {
      0%{
        transform: scale(0.8);
        opacity: 0;
      }

      50%{
        opacity: 1;
      }

      100%{
        transform: scale(1.1);
        opacity: 0;
      }
    }
  }

  }
`

export const ListStoreMenu = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    label{
      font-size: 12px;
    }
  }

  div:nth-child(1){
    position: relative;

    ::before, ::after{
    content: "";
    display: ${props => props.animation == false ? "none" : "none"};
    position: absolute;
    left: -15px;
    right: -15px;
    top: -15px;
    bottom: -15px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: animatePulse 0.8s linear infinite;

      @keyframes animatePulse {
      0%{
        transform: scale(0.8);
        opacity: 0;
      }

      50%{
        opacity: 1;
      }

      100%{
        transform: scale(1.2);
        opacity: 0;
      }
    }
  }

  }
`