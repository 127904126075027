import React, { useState, useEffect } from "react";
import {
  ButtonHelp,
  ButtonReset,
  ButtonSave,
  Container,
  IconOption,
  IconOptionContainer,
  InputTextButton,
  ItemLine,
  ItemLineColor,
  SelectCustom,
  SliderCustom,
  Title,
} from "./styled";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from "react-redux";
import IconReset from "../../../../assets/imgs/icon-reset.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  BarConfigTitle,
  BoxTitle,
  ItemBar,
  ItemColor,
  ItemColorCustom,
} from "../bar-config-avancado/styled";

import IconTextLeft from "../../../../assets/imgs/text-left.svg";
import IconTextRight from "../../../../assets/imgs/text-right.svg";
import IconTextCenter from "../../../../assets/imgs/text-center.svg";
import IconTextBold from "../../../../assets/imgs/text-bold.svg";
import IconTextUnderline from "../../../../assets/imgs/text-underline.svg";
import IconTextItalic from "../../../../assets/imgs/text-italic.svg";
import { getTranslation } from "../../../../translations";
import { AlignOptions, AlignOptionsItem } from "../bar-config-box/styled";
import { AiFillGift, AiFillHeart } from "react-icons/ai";
import { BsSuitHeartFill } from "react-icons/bs";
import { RiHeart2Fill } from "react-icons/ri";
import { PiHeartBreakFill } from "react-icons/pi";

const BarConfigIcon = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.reducerGetConfig);
  const configControl = useSelector((state) => state.reducerControlConfig);
  const history = useHistory();

  const translation = getTranslation(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );
  const handleIconClick = (iconType) => {
    dispatch({
      type: "CONFIG_CONTROL_SET",
      payload: { icon_type: iconType },
    });
  };

  const saveTheme = () => {
    dispatch({
      type: "SAGA_UPDATE_CONFIG",
      payload: {
        id: config.data?.id,
        icon_type: configControl.icon_type,
        icon_color: configControl.icon_color,
        icon_position: configControl.icon_position,
        icon_size: configControl.icon_size,
      },
    });
  };

  const getDefaultTheme = () => {
    const themaDefault = {
      id: config.data?.id,
      icon_type: "heart",
      icon_color: "#FF0092",
      icon_position: "right-top",
      icon_size: 24,
    };
    dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault });
  };

  return (
    <Container>
      <Title>
        <span
          onClick={() => {
            history.push("/personalizar");
          }}
        >
          <IoIosArrowBack />
        </span>
        <label>{translation.thema.menu.iconeProduto}</label>
      </Title>

      <ItemLine>
        <label> {translation.thema.barraBotaoFavoritar.itemIcone}</label>
        <IconOptionContainer>
          <IconOption
            onClick={() => handleIconClick("heart")}
            selected={configControl.icon_type === "heart"}
            style={{
              borderColor:
                configControl.icon_type === "heart" ? "#000000" : "#dcdcdc",
            }}
          >
            <AiFillHeart
              color={
                configControl.icon_type === "heart" ? "#000000" : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart02")}
            selected={configControl.icon_type === "heart02"}
            style={{
              borderColor:
                configControl.icon_type === "heart02" ? "#000000" : "#dcdcdc",
            }}
          >
            <BsSuitHeartFill
              color={
                configControl.icon_type === "heart02" ? "#000000" : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart03")}
            selected={configControl.icon_type === "heart03"}
            style={{
              borderColor:
                configControl.icon_type === "heart03" ? "#000000" : "#dcdcdc",
            }}
          >
            <RiHeart2Fill
              color={
                configControl.icon_type === "heart03" ? "#000000" : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart04")}
            selected={configControl.icon_type === "heart04"}
            style={{
              borderColor:
                configControl.icon_type === "heart04" ? "#000000" : "#dcdcdc",
            }}
          >
            <PiHeartBreakFill
              color={
                configControl.icon_type === "heart04" ? "#000000" : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("gift")}
            selected={configControl.icon_type === "gift"}
            style={{
              borderColor:
                configControl.icon_type === "gift" ? "#000000" : "#dcdcdc",
            }}
          >
            <AiFillGift
              color={configControl.icon_type === "gift" ? "#000000" : "#dcdcdc"}
            />
          </IconOption>
        </IconOptionContainer>
        {/* <SelectCustom
          style={{ width: "100%", height: "40px", fontSize: "12px" }}
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { icon_type: e.target.value },
            });
          }}
        >
          <option
            value="heart"
            selected={configControl.icon_type == "heart" ? true : false}
          >
            {translation.thema.barraBotaoFavoritar.coracao}
          </option>
          <option
            value="heart02"
            selected={configControl.icon_type == "heart02" ? true : false}
          >
            {translation.thema.barraBotaoFavoritar.coracao2}
          </option>
          <option
            value="heart03"
            selected={configControl.icon_type == "heart03" ? true : false}
          >
            {translation.thema.barraBotaoFavoritar.coracao3}
          </option>
          <option
            value="heart04"
            selected={configControl.icon_type == "heart04" ? true : false}
          >
            {translation.thema.barraBotaoFavoritar.coracao4}
          </option>
          <option
            value="gift"
            selected={configControl.icon_type == "gift" ? true : false}
          >
            {translation.thema.barraBotaoFavoritar.presente}{" "}
          </option>
        </SelectCustom> */}
      </ItemLine>
      <ItemLine>
        <label style={{ marginBottom: "8px" }}>Ajuste: </label>
        <SliderCustom
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { icon_size: e.target.value },
            });
          }}
        >
          <label>Tamanho</label>
          <input
            type="range"
            value={configControl.icon_size}
            id="volume"
            name="volume"
            min="20"
            max="30"
          />
        </SliderCustom>
      </ItemLine>

      <ItemLine style={{ gap: "10px" }}>
        <ItemLineColor>
          <label> {translation.thema.barraBotaoFavoritar.corIcone}</label>
          <ItemColor>
            <span>
              <input
                type={"color"}
                value={configControl.icon_color}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { icon_color: e.target.value },
                  })
                }
              />
              <label>{configControl.icon_color}</label>
            </span>
          </ItemColor>
        </ItemLineColor>
      </ItemLine>

      <ItemLine style={{ gap: "5px" }}>
        <label>{translation.thema.barraCaixaFavoritos.posicao}</label>
        <AlignOptions>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "left-top" },
              });
            }}
            active={configControl.icon_position == "left-top" ? true : false}
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "left-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "center-top" },
              });
            }}
            active={configControl.icon_position == "center-top" ? true : false}
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "center-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                right: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "right-top" },
              });
            }}
            active={configControl.icon_position == "right-top" ? true : false}
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "right-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "left-center" },
              });
            }}
            active={configControl.icon_position == "left-center" ? true : false}
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "left-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "center-center" },
              });
            }}
            active={
              configControl.icon_position == "center-center" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "center-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                left: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "right-center" },
              });
            }}
            active={
              configControl.icon_position == "right-center" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "right-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "left-bottom" },
              });
            }}
            active={configControl.icon_position == "left-bottom" ? true : false}
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "left-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "center-bottom" },
              });
            }}
            active={
              configControl.icon_position == "center-bottom" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "center-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                left: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { icon_position: "right-bottom" },
              });
            }}
            active={
              configControl.icon_position == "right-bottom" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.icon_position == "right-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
        </AlignOptions>
      </ItemLine>

      <ButtonHelp>
        <img src={IconBossVideo} />
        <label>{translation.thema.acoes.ajuda}</label>
        <img style={{ width: "24px", height: "24px" }} src={IconVideo} />
      </ButtonHelp>

      <ButtonSave onClick={() => saveTheme()}>
        {translation.thema.acoes.salvar}
      </ButtonSave>

      <ButtonReset onClick={() => getDefaultTheme()}>
        <label>{translation.thema.acoes.restaurarPadrao}</label>
        <img src={IconReset} />
      </ButtonReset>
    </Container>
  );
};

export default BarConfigIcon;
