import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Alert from "../../../../components/Alert/index";
import Contents from "../../../../components/Contents";
import CardPlano from '../../components/CardPlano/index'
import ModalCupom from '../../components/ModalCupom/index'
import FormaPagamento from '../../components/FormaPagamento/index'
import TablePagamentos from '../../components/TablePagamentos/index'
import DadosNotaFiscal from '../../components/DadosNotaFiscal/index'
import Avisos from "../../components/Avisos";
import { Container, Cupom } from './styled'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PagamentoProvider } from "../../hooks/usePagamento";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import LoadingLogo from "../../../../components/Loading-logo-super-favoritos";
import AlertCloseBoss from "../../../../components/alert-close-boss copy";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { getTranslation } from "../../../../translations";


const Home = () => {
    const payments = useSelector(state => state.reducerPayments)
    const dispatch = useDispatch()
    const [visibleModalInfor, setVisibleModalInfor] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        dispatch({ type: 'SAGA_LIST_PAYMENTS' })
    }, [])


    return (
        <Body>
            <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={"https://www.youtube.com/embed/IVZTyiJKwJ4"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1", minWidth: '0%' }} >
                <Header />
                <Contents>
                    {payments.loading ?
                        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <LoadingLogo />
                        </div> :

                        <PagamentoProvider>
                            <Container>

                                <AlertCloseBoss close={true} margin={"0px 0px 30px 0px"} visible={true} >
                                    <label style={{ alignItems: "center", margin: "0px" }} >
                                        {translation.alerts.assinatura}
                                    </label>
                                    <span style={{}}>
                                        <label onClick={() => { setVisibleModalInfor(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                            {translation.alerts.verComoFunciona}
                                        </label>
                                    </span>
                                </AlertCloseBoss>

                                <Avisos />

                                {payments?.data?.dadosPlanos?.planos && Object.values(payments?.data?.dadosPlanos?.planos).map((plan, index) => {
                                    if (!plan.legacy || plan.id == payments.data.currentPlan.id) {
                                        return (
                                            <div style={{ display: "flex", gap: "20px", marginTop: "30px" }}>
                                                <CardPlano key={index} currentPlan={payments.data.currentPlan} idPlan={plan.id} name={plan.name} value={plan.value} features={payments.data.dadosPlanos.funcionalidades} />
                                            </div>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}

                                <TablePagamentos faturasInitialValue={payments?.data?.transactions ?? []} />

                            </Container>
                        </PagamentoProvider>
                    }
                </ Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    );
}

export default Home