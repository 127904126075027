import { useEffect } from 'react';
import { Container } from './styled'

const Card = (props) => {

    useEffect(() => {
        const script2 = document.getElementById("script-support");
        if (script2) {
            script2.parentNode.removeChild(script2);
        }
        const script = document.createElement('script');
        script.src = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/656630f40bbeb.js" : "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/6560ba55d98c7.js"
        script.id = "script-support";
        script.async = true;
        document.body.appendChild(script);
        // return () => {
        //     document.body.removeChild(script);
        // }
    }, [])

    return (
        <Container background={props.background} color={props.color}>
            <span>{props.headerText}</span>
            <strong>{props.mainText}</strong>
            <a href={props.btnAction} target={props.headerText !== 'Atendimento'} rel="noreferrer">{props.btnText}</a>
        </Container>
    );
}

export default Card