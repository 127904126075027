import { Container, ButtonCustom, Close, Title, Item, ItemTitle, InputCustomShopify, Code, OptionsLabel, Box, BoxIfrane } from "./styled";
import Modal from 'react-modal';
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Alert from "../../../../components/Alert";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { platforms } from "../../pages/home/dataCards";
import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
        overflow: "auto",
        maxHeight: "90vh",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalIntegration = (props) => {

    const history = useHistory();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    console.log("props", props)

    return (
        <Modal
            isOpen={props.visible}
            onRequestClose={() => { props.setVisibleModal(false) }}
            style={customStyles}
            ariaHideApp={false}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}                >
                    <GrFormClose size={25} color={"#5e6278"} />
                </Close>
                <Title>
                    <strong>
                        {translation.integrations.modalIntegration.configuracao} {translation.integrations.nuvemshop}
                    </strong>
                </Title>
                <ItemTitle>
                    {platforms.nuvemshop.body.title}
                </ItemTitle>
                {/* {props.platform.body.steps.map((item) => (<Item key={Math.random()}>{item}</Item>))} */}
                <Item>
                    {translation.integrations.dataCard.nuvemshop.steps1}
                </Item>
                <Item>
                    {translation.integrations.dataCard.nuvemshop.steps2}
                </Item>
                <Item>
                    {translation.integrations.dataCard.nuvemshop.steps3}
                </Item>
                <Alert margin="0px" padding="10px" background="red" >
                    <label style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</label>
                </Alert>
                <ButtonCustom onClick={() => {
                    //window.location = props.platform.action
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ?
                        window.location = `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize` :
                        window.location = `https://www.tiendanube.com/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
                }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalIntegration
