import React, { useState, useEffect } from "react";
import {
  ButtonHelp,
  ButtonReset,
  ButtonSave,
  Container,
  IconOption,
  IconOptionContainer,
  InputTextButton,
  ItemLine,
  ItemLineColor,
  SelectCustom,
  Title,
} from "./styled";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from "react-redux";
import IconReset from "../../../../assets/imgs/icon-reset.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  BarConfigTitle,
  BoxTitle,
  ItemBar,
  ItemColor,
  ItemColorCustom,
} from "../bar-config-avancado/styled";

import IconTextLeft from "../../../../assets/imgs/text-left.svg";
import IconTextRight from "../../../../assets/imgs/text-right.svg";
import IconTextCenter from "../../../../assets/imgs/text-center.svg";
import IconTextBold from "../../../../assets/imgs/text-bold.svg";
import IconTextUnderline from "../../../../assets/imgs/text-underline.svg";
import IconTextItalic from "../../../../assets/imgs/text-italic.svg";
import { getTranslation } from "../../../../translations";
import { AiFillGift, AiFillHeart } from "react-icons/ai";
import { BsSuitHeartFill } from "react-icons/bs";
import { RiHeart2Fill } from "react-icons/ri";
import { PiHeartBreakFill } from "react-icons/pi";

const BarConfigButton = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.reducerGetConfig);
  const configControl = useSelector((state) => state.reducerControlConfig);
  const history = useHistory();
  const [clickXpath, setClickXpath] = useState(0);

  const translation = getTranslation(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );
  const handleIconClick = (iconType) => {
    dispatch({
      type: "CONFIG_CONTROL_SET",
      payload: { widget_button_icon: iconType },
    });
  };

  const saveTheme = () => {
    dispatch({
      type: "SAGA_UPDATE_CONFIG",
      payload: {
        id: config.data?.id,
        widget_button_icon: configControl.widget_button_icon,
        widget_button_text: configControl.widget_button_text,
        widget_button_text_font_size:
          configControl.widget_button_text_font_size,
        widget_button_text_align: configControl.widget_button_text_align,
        widget_button_text_bolt: configControl.widget_button_text_bolt ? 1 : 0,
        widget_button_text_italic: configControl.widget_button_text_italic
          ? 1
          : 0,
        widget_button_text_underline: configControl.widget_button_text_underline
          ? 1
          : 0,
        widget_button_text_color: configControl.widget_button_text_color,
        widget_button_border_radius: configControl.widget_button_border_radius,
        widget_button_color_background:
          configControl.widget_button_color_background,
        widget_button_color_icon: configControl.widget_button_color_icon,
        widget_button_text_aux: configControl.widget_button_text_aux,
        xpath_button: configControl.xpath_button,
      },
    });
  };

  const getDefaultTheme = () => {
    const themaDefault = {
      id: config.data?.id,
      widget_button_icon: "heart",
      widget_button_text: "Favoritar",
      widget_button_text_font_size: "14px",
      widget_button_text_align: "center",
      widget_button_text_bolt: 0,
      widget_button_text_italic: 0,
      widget_button_text_underline: 0,
      widget_button_text_color: "#ffffff",
      widget_button_border_radius: "8",
      widget_button_color_background: "#4745ED",
      widget_button_color_icon: "#ffffff",
      widget_button_text_aux: "REMOVER DOS FAVORITOS",
    };
    dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault });
  };

  return (
    <Container>
      <Title>
        <span
          onClick={() => {
            history.push("/personalizar");
          }}
        >
          <IoIosArrowBack />
        </span>
        <label>{translation.thema.barraBotaoFavoritar.titulo}</label>
      </Title>

      <ItemLine style={{ gap: "10px" }}>
        <label> {translation.thema.barraBotaoFavoritar.itemCores}</label>
        <ItemLineColor>
          <label> {translation.thema.barraBotaoFavoritar.corFundo}</label>
          <ItemColor>
            <span>
              <input
                type={"color"}
                value={configControl.widget_button_color_background}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_color_background: e.target.value },
                  })
                }
              />
              <label>{configControl.widget_button_color_background}</label>
            </span>
          </ItemColor>
        </ItemLineColor>

        <ItemLineColor>
          <label> {translation.thema.barraBotaoFavoritar.corIcone}</label>
          <ItemColor>
            <span>
              <input
                type={"color"}
                value={configControl.widget_button_color_icon}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_color_icon: e.target.value },
                  })
                }
              />
              <label>{configControl.widget_button_color_icon}</label>
            </span>
          </ItemColor>
        </ItemLineColor>
      </ItemLine>

      <ItemLine style={{ gap: "4px" }}>
        <label> {translation.thema.barraBotaoFavoritar.itemIcone}</label>
        <IconOptionContainer>
          <IconOption
            onClick={() => handleIconClick("heart")}
            selected={configControl.widget_button_icon === "heart"}
            style={{
              borderColor:
                configControl.widget_button_icon === "heart"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <AiFillHeart
              color={
                configControl.widget_button_icon === "heart"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart02")}
            selected={configControl.widget_button_icon === "heart02"}
            style={{
              borderColor:
                configControl.widget_button_icon === "heart02"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <BsSuitHeartFill
              color={
                configControl.widget_button_icon === "heart02"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart03")}
            selected={configControl.widget_button_icon === "heart03"}
            style={{
              borderColor:
                configControl.widget_button_icon === "heart03"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <RiHeart2Fill
              color={
                configControl.widget_button_icon === "heart03"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart04")}
            selected={configControl.widget_button_icon === "heart04"}
            style={{
              borderColor:
                configControl.widget_button_icon === "heart04"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <PiHeartBreakFill
              color={
                configControl.widget_button_icon === "heart04"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("gift")}
            selected={configControl.widget_button_icon === "gift"}
            style={{
              borderColor:
                configControl.widget_button_icon === "gift"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <AiFillGift
              color={
                configControl.widget_button_icon === "gift"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
        </IconOptionContainer>
        {/* <SelectCustom style={{ width: "100%", height: "40px", fontSize: "12px" }} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { widget_button_icon: e.target.value } }) }}                >
                    <option value="heart" selected={configControl.widget_button_icon == "heart" ? true : false}>{translation.thema.barraBotaoFavoritar.coracao}</option>
                    <option value="gift" selected={configControl.widget_button_icon == "gift" ? true : false} >{translation.thema.barraBotaoFavoritar.presente} </option>
                </SelectCustom> */}
      </ItemLine>

      <BoxTitle>
        <label style={{ fontSize: "14px" }}>
          {" "}
          {translation.thema.barraBotaoFavoritar.itemTexto}
        </label>
        <div style={{ position: "relative" }}>
          <BarConfigTitle>
            <select
              onChange={(e) => {
                dispatch({
                  type: "CONFIG_CONTROL_SET",
                  payload: { widget_button_text_font_size: e.target.value },
                });
              }}
            >
              <option
                value={"9px"}
                selected={
                  configControl.widget_button_text_font_size == "9px"
                    ? true
                    : false
                }
              >
                9px
              </option>
              <option
                value={"10px"}
                selected={
                  configControl.widget_button_text_font_size == "10px"
                    ? true
                    : false
                }
              >
                10px
              </option>
              <option
                value={"11px"}
                selected={
                  configControl.widget_button_text_font_size == "11px"
                    ? true
                    : false
                }
              >
                11px
              </option>
              <option
                value={"12px"}
                selected={
                  configControl.widget_button_text_font_size == "12px"
                    ? true
                    : false
                }
              >
                12px
              </option>
              <option
                value={"14px"}
                selected={
                  configControl.widget_button_text_font_size == "14px"
                    ? true
                    : false
                }
              >
                14px
              </option>
              <option
                value={"16px"}
                selected={
                  configControl.widget_button_text_font_size == "16px"
                    ? true
                    : false
                }
              >
                16px
              </option>
              <option
                value={"18px"}
                selected={
                  configControl.widget_button_text_font_size == "18px"
                    ? true
                    : false
                }
              >
                18px
              </option>
            </select>

            <ItemColorCustom color={configControl.widget_button_text_color}>
              <label for={"testes"}>A</label>
              <div />
              <input
                id={"testes"}
                type={"color"}
                value={configControl.widget_button_text_color}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_text_color: e.target.value },
                  })
                }
              />
            </ItemColorCustom>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_button_text_align == "left" ? true : false
              }
            >
              <img
                src={IconTextLeft}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_text_align: "left" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_button_text_align == "center"
                  ? true
                  : false
              }
            >
              <img
                src={IconTextCenter}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_text_align: "center" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_button_text_align == "right" ? true : false
              }
            >
              <img
                src={IconTextRight}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_button_text_align: "right" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_button_text_bolt ||
                configControl.widget_button_text_bolt == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextBold}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_button_text_bolt:
                        !configControl.widget_button_text_bolt,
                    },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_button_text_italic ||
                configControl.widget_button_text_italic == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextItalic}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_button_text_italic:
                        !configControl.widget_button_text_italic,
                    },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_button_text_underline ||
                configControl.widget_button_text_underline == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextUnderline}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_button_text_underline:
                        !configControl.widget_button_text_underline,
                    },
                  });
                }}
              />
            </ItemBar>
          </BarConfigTitle>
        </div>
        <ItemLine style={{ gap: "2px", marginTop: "40px" }}>
          <label style={{ fontSize: "9px" }}>
            {" "}
            {translation.thema.barraBotaoFavoritar.texto01}
          </label>
          <InputTextButton
            type="text"
            placeholder="Texto do botão"
            value={configControl.widget_button_text}
            onChange={(e) => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_button_text: e.target.value },
              });
            }}
          />
        </ItemLine>
        <ItemLine style={{ gap: "2px" }}>
          <label style={{ fontSize: "9px" }}>
            {" "}
            {translation.thema.barraBotaoFavoritar.texto02}
          </label>
          <InputTextButton
            type="text"
            placeholder="Texto do botão"
            value={configControl.widget_button_text_aux}
            onChange={(e) => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_button_text_aux: e.target.value },
              });
            }}
          />
        </ItemLine>
      </BoxTitle>

      <ItemLine>
        <label> {translation.thema.barraBotaoFavoritar.arredondamento}</label>
        <input
          type="range"
          min="0"
          max="20"
          value={configControl?.widget_button_border_radius}
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_button_border_radius: e.target.value },
            });
          }}
        />
      </ItemLine>

      {clickXpath > 9 && (
        <ItemLine style={{ gap: "2px", marginTop: "40px" }}>
          <label style={{ fontSize: "9px" }}> Xpath</label>
          <InputTextButton
            type="text"
            placeholder="Posição"
            value={configControl.xpath_button}
            onChange={(e) => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { xpath_button: e.target.value },
              });
            }}
          />
        </ItemLine>
      )}

      <ButtonHelp
        onClick={() => {
          setClickXpath(clickXpath + 1);
        }}
      >
        <img src={IconBossVideo} />
        <label>{translation.thema.acoes.ajuda}</label>
        <img style={{ width: "24px", height: "24px" }} src={IconVideo} />
      </ButtonHelp>

      <ButtonSave onClick={() => saveTheme()}>
        {translation.thema.acoes.salvar}
      </ButtonSave>

      <ButtonReset onClick={() => getDefaultTheme()}>
        <label>{translation.thema.acoes.restaurarPadrao}</label>
        <img src={IconReset} />
      </ButtonReset>
    </Container>
  );
};

export default BarConfigButton;
