import { TitleModal, BodyModal, ItemModal, Option } from "./styled";
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AiOutlineLoading, AiFillCheckCircle, AiFillCaretRight } from "react-icons/ai";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { platforms } from "../../pages/home/dataCards";
import { BsTools } from "react-icons/bs";
import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalComponent = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const statusIntegration = useSelector(state => state.reducerIntegration)
    const controlPlatform = useSelector(state => state.reducerControlPlatform)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const dispatch = useDispatch()

    const getIconIntegration = () => {
        if (!statusIntegration.loading && !statusIntegration.error && statusIntegration.data?.length == 0) {
            return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
        } else {
            if (statusIntegration.loading === true) return <AiOutlineLoading size={20} color={"green"} />
            else {
                if (statusIntegration.error === true) return <MdError size={20} color={"red"} />
                else return <AiFillCheckCircle size={20} color={"green"} />
            }
        }
    }

    useEffect(() => {
        console.log("3 => ", controlPlatform.data.typeIntegration)

        if (controlPlatform.data.typeIntegration == "error") {

        } else if (controlPlatform.data.typeIntegration) {
            if (JSON.parse(localStorage.getItem("depoimentos@login")).integration == undefined) {
                setModalIsOpen(true)
                dispatch({ type: "SAGA_INTEGRATE_INTEGRATION", payload: controlPlatform.data })
            }
        }
    }, [])

    useEffect(() => {

        console.log("statusIntegration", statusIntegration)

        if (statusIntegration.error) {
            statusIntegration.data?.length == 0 ?
                toast.error("Erro na integração.", { onClose: setModalIsOpen(false), toastId: 0 }) :
                toast.error(statusIntegration.data?.description + ` (${"mail"})`, { onClose: setModalIsOpen(false), toastId: 0 })
        }
        else if (statusIntegration.data?.length != 0) {
            toast.success(`${controlPlatform.data.typeIntegration == "custom" ? "Integração Manual" : controlPlatform.data.typeIntegration}  integrada com sucesso.`, { onClose: setModalIsOpen(false), toastId: 0 })
        }

    }, [statusIntegration])

    return (
        <Modal
            isOpen={modalIsOpen}
            //isOpen={true}
            //onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            ariaHideApp={false}
        >

            <TitleModal>
                {platforms[controlPlatform.data?.typeIntegration]?.title === "Custom" ?
                    <BsTools size={25} color={"#FE852B"} /> :
                    <span>
                        < img src={platforms[controlPlatform.data?.typeIntegration]?.image} />
                    </span>}
                <label>{platforms[controlPlatform.data?.typeIntegration]?.title == "Custom" ? "Integração Manual" : platforms[controlPlatform.data?.typeIntegration]?.title} </label>
            </TitleModal>
            <BodyModal>
                <Option>
                    <label>{translation.integrations.modal.acao}</label>
                    <label>{translation.integrations.modal.progresso}</label>
                    <div>{translation.integrations.modal.feedback}</div>
                </Option>
                <Option loading={statusIntegration.loading}>
                    <label>{translation.integrations.modal.integracao}</label>
                    <label>{translation.integrations.modal.de}</label>
                    <div>
                        <span>
                            {getIconIntegration()}
                        </span>
                    </div>
                </Option>
            </BodyModal>
        </Modal>
    )
}

export default ModalComponent
