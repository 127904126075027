import { put, call, delay } from "redux-saga/effects";
import { POST, GET, DELETE, POST_ADMIN_CUSTOM } from "../../../../services/api";

export function* integrate(action) {

    console.log("teste integrate action", action)

    yield put({ type: "INTEGRATION_REQUEST", })

    const result = yield call(POST, { endpoint: "/integrations", data: action.payload })

    if (result == false) {
        yield put({ type: "INTEGRATION_ERROR", payload: result ? result.data : [] })
        yield put({ type: "INTEGRATION_RESET" })
    } else if (result.data.error) {
        if (result?.data?.data?.redirect?.length > 0) {
            window.location = result?.data?.data?.redirect
        } else {
            yield put({ type: "INTEGRATION_ERROR", payload: result.data.error ? result.data : [] })
            yield put({ type: "INTEGRATION_RESET" })
        }
    } else {
        yield put({ type: "INTEGRATION_SUCCESS", payload: result.data })
        localStorage.removeItem("nuvemshop@code")
        let local = JSON.parse(localStorage.getItem("depoimentos@login"))
        local.integration = result.data.data
        localStorage.setItem('depoimentos@login', JSON.stringify(local))

        yield put({ type: "INTEGRATION_RESET" })
        yield call(platforms)
    }

    localStorage.removeItem("nuvemshop@code")
    yield put({ type: "CONTROL_PLATFORM_RESET" })
}



export function* platforms() {
    yield put({ type: "GET_PLATFORMS_REQUEST", })
    const result = yield call(GET, { endpoint: "/integrations" })
    if (result === false) {
        yield put({ type: "GET_PLATFORMS_ERROR" })
    } else {
        yield put({ type: "GET_PLATFORMS_SUCCESS", payload: result.data.data })
    }
}

export function* deleteIntegration(action) {
    yield put({ type: "INTEGRATION_DELETE_REQUEST", })
    const result = yield call(DELETE, { endpoint: `/integrations/platform/${action.payload.platform}` })
    if (result === false) {
        yield put({ type: "INTEGRATION_DELETE_ERROR" })
    } else {
        yield put({ type: "INTEGRATION_DELETE_SUCCESS", payload: result.data })
        if (action.payload.platform == "dropi") {
            let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
            delete newLocal.integrationDropi
            localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
        } else {
            let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
            delete newLocal.integration
            localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
        }
        yield call(platforms)
    }
    yield put({ type: "INTEGRATION_DELETE_RESET" })
    
}

