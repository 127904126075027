import React, { useEffect, useState, useRef } from 'react'
import { AbaItem, AbasOptions, BoxSlider, Container, Divider, NavBox, Title } from './styled'
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import CardSlider01 from '../card-slider-01';

import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useSelector } from 'react-redux';
import Card01 from '../card-01';
import Card02 from '../card-02';
import Card04 from '../card-04';

import IconBolsa from "../../../../assets/imgs/preview/icon-bolsa.svg";
import IconSapato from "../../../../assets/imgs/preview/icon-sapato.svg";
import IconOculos from "../../../../assets/imgs/preview/icon-oculos.svg";
import { getTranslation } from '../../../../translations';

const Slider = () => {
    const swiperRef = useRef(null);
    const swiperRef2 = useRef(null);
    const [activeIndex, setActiveIndex] = useState(3)
    const [countSliders, setCountSliders] = useState(8)
    const config = useSelector(state => state.reducerControlConfig)

    const [menuActive, setmenuActive] = useState("favoritos")

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        console.log("menuActive", menuActive)
    }, [menuActive])

    useEffect(() => {
        console.log("config widget type", config.widget_type)
        if (config.widget_type == "favorites-historic" || config.widget_type == "favorites") {
            setmenuActive("favoritos")
        } else if (config.widget_type == "historic") {
            setmenuActive("historico")
        }
    }, [config])

    const ToSlideNext = () => {
        if (activeIndex < countSliders) {
            swiperRef.current.swiper.slideTo(activeIndex + 1, 0)
            setActiveIndex(activeIndex + 1)
        } else {
            swiperRef.current.swiper.slideTo(1, 0)
            setActiveIndex(1)
        }

    }

    const ToSlidePrev = () => {
        if (activeIndex == 1) {
            swiperRef.current.swiper.slideTo(countSliders, 0)
            setActiveIndex(countSliders)
        } else {
            swiperRef.current.swiper.slideTo(activeIndex - 1, 0)
            setActiveIndex(activeIndex - 1)
        }
    }

    useEffect(() => {
        console.log("config ", config.slider_auto || config.slider_auto == 1)
        console.log("config swiperRef ", swiperRef?.current?.swiper)

        setTimeout(() => {
            if (config.slider_auto || config.slider_auto == 1) {
                swiperRef?.current?.swiper?.autoplay?.start()
            } else {
                swiperRef?.current?.swiper?.autoplay?.stop()
            }
        }, 1000);

    }, [config])

    const getQtdSlider = () => {
        switch (config.widget_card_image_size) {
            case "80x80":
                return 6
            case "100x100":
                return 5
            case "150x150":
                return 3
            default:
                return 6
        }
    }


    return (
        <Container background={config.color_widget} >
            {/* <Title font={config.font} color={config.color_font_title} bold={config.title_bolt} size={config.title_font_size} italic={config.title_italic} >
                <label>
                    {config.title}
                </label>

                <NavBox style={{ visibility: config.slider_scroll || config.slider_scroll == 1 ? "visible" : "hidden" }} color={config.color_scroll_background} colorHover={config.color_scroll}>
                    <MdArrowBackIos onClick={() => { ToSlidePrev() }} />
                    <MdArrowForwardIos onClick={() => { ToSlideNext() }} />
                </NavBox>

            </Title> */}

            <Title font={config.font} >

                <div style={{
                    color: config.color_font_title,
                    flex: 1,
                    fontWeight: config.title_bolt == 1 ? "bold" : "initial",
                    fontSize: `${config.title_font_size}`,
                    fontStyle: config.title_italic || config.title_italic == 1 ? 'italic' : "initial"
                }}>
                    {/* Histórico e Favoritos */}
                    {config.title}
                </div>

                <AbasOptions>
                    {config.widget_type == "favorites-historic" || config.widget_type == "favorites" ?
                        <AbaItem color={config.widget_color_aba_text} style={{ position: "relative" }} backgroundColor={config.widget_color_aba} borderColor={config.widget_color_aba_border} active={menuActive == "favoritos" || menuActive == null ? true : false} onClick={() => { setmenuActive("favoritos") }}>
                            {translation.thema.barraListaFavoritos.favoritos} <span>10</span>
                        </AbaItem> : null}

                    {config.widget_type == "favorites-historic" || config.widget_type == "historic" ?
                        <AbaItem color={config.widget_color_aba_text} style={{ position: "relative" }} backgroundColor={config.widget_color_aba} borderColor={config.widget_color_aba_border} active={menuActive == "historico" ? true : false} onClick={() => { setmenuActive("historico") }}>
                            {translation.thema.barraListaFavoritos.historico} <span>20</span>
                        </AbaItem> : null}
                </AbasOptions>

                <NavBox style={{ display: config.slider_scroll || config.slider_scroll == 1 ? "flex" : "none" }} color={config.color_scroll_background} colorHover={config.color_scroll}>
                    <MdArrowBackIos onClick={() => { ToSlidePrev() }} />
                    <MdArrowForwardIos onClick={() => { ToSlideNext() }} />
                </NavBox>

            </Title>
            <Divider background={config.color_line} />
            <BoxSlider>
                <Swiper
                    ref={swiperRef}
                    id='swiper-multi'
                    modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
                    //navigation
                    onSwiper={(swiper) => console.log("swiper", swiper)}
                    onSlideChange={(slider) => setActiveIndex(slider.activeIndex)}
                    loop={true}
                    speed={2000}
                    effect={"slide"}
                    //spaceBetween={43}
                    slidesPerView={getQtdSlider()}
                >

                    {config.widget_card == "modelo01" &&
                        <>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <CardSlider01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                        </>}

                    {config.widget_card == "modelo02" &&
                        <>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card01 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                        </>}

                    {config.widget_card == "modelo03" &&
                        <>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card02 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                        </>}

                    {config.widget_card == "modelo04" &&
                        <>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Óculos", price: "12225", url: IconOculos }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bolsa", price: "12225", url: IconBolsa }} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Card04 data={{ name: "Bota", price: "12225", url: IconSapato }} />
                            </SwiperSlide>
                        </>}

                </Swiper>
            </BoxSlider>
        </Container>
    )
}

export default Slider