import { Container, Btn } from "./styled";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai"
import ModalInfor from "../Modal-Integration";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../../../translations";

const Card = (props) => {

    const [visibleModalInfor, setVisibleModalInfor] = useState(false);
    const statusDeleteIntegration = useSelector(state => state.reducerDeleteIntegration)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    console.log("props card", props)


    useEffect(() => {
        if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data?.length == 0) {
        } else {
            const id = "statusDeleteIntegration"
            if (statusDeleteIntegration.loading) {
                toast.loading(translation.integrations.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteIntegration.error) {
                    toast.update(id, { render: translation.integrations.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.integrations.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteIntegration])

    const deleteIntegration = () => { dispatch({ type: "SAGA_DELETE_INTEGRATION", payload: { platform: "nuvemshop" } }) }

    return (
        <Container titleColor={props.platform?.titleColor} >
            {props.platform.title == "Nuvemshop" || props.platform.title == "Tiendanube" ?
                <Btn onClick={() => { if (!props.install) setVisibleModalInfor(true) }}                                 >
                    {props.install ?
                        <span>
                            <AiFillCheckCircle size={25} color={"green"} />
                        </span> :
                        <label>Instalar</label>}
                </Btn> : null}

            <ModalInfor
                platform={props.platform}
                visible={visibleModalInfor}
                setVisibleModal={setVisibleModalInfor}
                item={props.item}
            />

            <ModalConfirmation
                confirmation={deleteIntegration}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={`${translation.integrations.card.desejaExcluir} ${props.platform.title} ?`}
                infor={`Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`}
            />

            <div onClick={() => { !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true) }}            >
                <img src={props.platform?.image} height={50} />
                {/* <label>{props.platform?.message}</label> */}
                <label>{translation.integrations.dataCard.nuvemshop.message}</label>
            </div>

            <span onClick={() => { !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true) }}            >
                <label>
                    {/* {props.platform?.title} */}
                    {translation.integrations.nuvemshop}
                </label>
            </span>
        </Container >
    )
}

export default Card
