import { useSelector } from "react-redux";
import { BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, Container, InforPlus, InforPlusBtn, InforPlusText } from "./styled";
import { usePagamento } from "../../hooks/usePagamento";
import Button from "../../../../components/Button/index";
import { getTranslation } from "../../../../translations";

const Avisos = () => {

    //const payments = useSelector(state => state.reducerPayments)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    // if (!payments?.data?.user) {
    //     return (<></>);
    // }

    return (
        <InforPlus>
            <InforPlusText>
                <video width={300} muted autoplay={"autoplay"} loop={true}  >
                    <source src={"https://tools.empreender.com.br/empreender-plus/logo-animation.webm"} type="video/webm" />
                </video>
                <label>
                    {translation.assinatura.avisos.assine} <strong style={{ color: "#47fffe" }}> EMPREENDER PLUS </strong> {translation.assinatura.avisos.mensagem}
                </label>
            </InforPlusText>
            <InforPlusBtn>
                <label onClick={() => { window.open(process.env.REACT_APP_EPLUS_DOMAIN + `/planos`) }}>
                {translation.assinatura.avisos.acesse}
                </label>
            </InforPlusBtn>
        </InforPlus >
    );

}


export default Avisos