
import { Container, Box, BoxPreview } from './styled'
import { useSelector } from 'react-redux';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import { getTranslation } from '../../../../translations';

const PreviewMenu = () => {

  const config = useSelector(state => state.reducerControlConfig)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  return (
    <BoxPreview>
      <Container img={imgBackground} font={config.font} >
        <Box backgroundColor={config.background_color_primary}>
          <label> {translation.thema.preview.menu}</label>
        </Box>
      </Container>

    </BoxPreview>

  )
}

export default PreviewMenu