import { BarLeft, BoxControlMobile, NewBarLeft, NewBarLeftTitle, ItemBarLeft } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useHistory, useLocation } from "react-router-dom";
import FormTema from "../../components/form-tema";
import BarConfigProduct from "../../components/bar-config";
import { Heart, HeartAdd, HeartCircle, HeartSearch } from 'iconsax-react';
import FormButton from "../../components/form-button";
import FormBoxIcon from "../../components/form-box-icon";
import BarConfigButton from "../../components/bar-config-button";
import BarConfigBox from "../../components/bar-config-box";
import PreviewMenu from "../../components/preview-menu";


import LoadingLogo from "../../../../components/Loading-logo-super-favoritos";
import { getTranslation } from "../../../../translations";
import AlertCloseBoss from "../../../../components/alert-close-boss copy";
import ModalInforVideo from "../../../../components/modal-infor-video";
import FormIcon from "../../components/form-icon-product";
import BarConfigIcon from "../../components/bar-config-icon";


const Home = () => {

    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const statusUpdateConfig = useSelector(state => state.reducerUpdateConfig)
    const config = useSelector(state => state.reducerGetConfig)
    const edit = useSelector(state => state.reducerControlEdit)
    const history = useHistory()

    const [visibleModalInfor, setVisibleModalInfor] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => { dispatch({ type: "SAGA_GET_CONFIG" }) }, [])

    useEffect(() => {
        const id = "statusUpdateConfig"
        if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data?.length == 0)) {
            if (statusUpdateConfig.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.toast.error, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.toast.sucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    dispatch({ type: "SAGA_GET_CONFIG", })
                }
            }
        } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.toast.error, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateConfig])

    const routerBar = () => {
        if (selectedMenu.pathname == "/personalizar") {
            return (
                <NewBarLeft>
                    <NewBarLeftTitle>
                        {translation.thema.menu.personalizar}
                    </NewBarLeftTitle>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/tema") }}>
                        <HeartSearch size="32" color="#808080" />
                        <label onClick={() => { history.push("/personalizar/tema") }}>
                            {translation.thema.menu.listaFavoritos}
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/botao") }}>
                        <HeartAdd size="32" color="#808080" />
                        <label onClick={() => { history.push("/personalizar/botao") }}>
                            {translation.thema.menu.botaoFavoritar}
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/caixa") }}>
                        <HeartCircle size="32" color="#808080" />
                        <label onClick={() => { history.push("/personalizar/caixa") }}>
                            {translation.thema.menu.caixaFavoritos}
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/icone") }}>
                        <Heart size="32" color="#808080" />
                        <label onClick={() => { history.push("/personalizar/icone") }}>
                        {translation.thema.menu.iconeProduto}
                        </label>
                    </ItemBarLeft>
                </NewBarLeft>
            )
        }
        if (selectedMenu.pathname == "/personalizar/tema") {
            return <BarConfigProduct />
        } else if (selectedMenu.pathname == "/personalizar/botao") {
            return <BarConfigButton />
        }
        else if (selectedMenu.pathname == "/personalizar/caixa") {
            return <BarConfigBox />
        }else if (selectedMenu.pathname == "/personalizar/icone") {
            return <BarConfigIcon/>
        } else {
            return <label></label>
        }
    }

    const getAlertText = (route) => {
        switch (route) {
            case "/personalizar":
                return translation.alerts.personalizar
            case "/personalizar/tema":
                return translation.alerts.personalizarTema
            case "/personalizar/botao":
                return translation.alerts.personalizarBotao
            case "/personalizar/caixa":
                return translation.alerts.personalizarCaixa
            default:
                return translation.alerts.personalizar
        }
    }

    const getAlertVideo = (route) => {
        switch (route) {
            case "/personalizar":
                return "vzKwIZRB-Ds"
            case "/personalizar/tema":
                return "QGGboZzmU5c"
            case "/personalizar/botao":
                return "oMhz0rHpC-A"
            case "/personalizar/caixa":
                return "QSh8O70kGv0"
            default:
                return "vzKwIZRB-Ds"
        }
    }

    useEffect(() => {
        if (localStorage.getItem("nuvemshop@code") && localStorage.getItem("nuvemshop@code") != null) {
            history.push({ pathname: '/integracoes/nuvemshop', })
        }
    }, [])

    return (
        <Body>

            <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${getAlertVideo(window.location.pathname)}`} />

            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents padding={"null"}>
                    <AlertCloseBoss close={true} margin={"20px 20px 20px 20px"} visible={true} >
                        <label style={{ alignItems: "center", margin: "0px" }} >
                            {getAlertText(window.location.pathname)}
                        </label>
                        <span style={{}}>
                            <label onClick={() => { setVisibleModalInfor(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                {translation.alerts.verComoFunciona}
                            </label>
                        </span>
                    </AlertCloseBoss>
                    {config.loading ?
                        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <LoadingLogo />
                        </div> :
                        <BoxControlMobile active={edit != "" ? true : false}>
                            <BarLeft>
                                {routerBar()}
                            </BarLeft>
                            <div style={{ display: "flex", flex: "1", justifyContent: "center", height: "100%", }}>
                                {console.log("div selectedMenu.pathname", selectedMenu.pathname)}
                                {selectedMenu.pathname == "/personalizar" && <PreviewMenu />}
                                {selectedMenu.pathname == "/personalizar/tema" && <FormTema />}
                                {selectedMenu.pathname == "/personalizar/botao" && <FormButton />}
                                {selectedMenu.pathname == "/personalizar/caixa" && <FormBoxIcon />}
                                {selectedMenu.pathname == "/personalizar/icone" && <FormIcon />}
                            </div>
                        </BoxControlMobile>
                    }
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home

