import { Container } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import Card from "../../components/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { isVisibleScript } from "../../../../utils/displayScript";
import { platforms } from "./dataCards";
import { AvaliacaoPopUpProvider } from "../../../../hooks/UseAvaliacaoPopUp";
import ModalInforVideoShopify from "../../../../components/modal-infor-video";
import LoadingLogo from "../../../../components/Loading-logo-super-favoritos";
import { getTranslation } from "../../../../translations";
import AlertCloseBoss from "../../../../components/alert-close-boss copy";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { useHistory } from "react-router-dom";

const Home = () => {

    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const dispatch = useDispatch()
    const history = useHistory()

    const [visibleModalInfor, setVisibleModalInfor] = useState(false)


    const [visibleModalInforVideoIntgration, setVisibleModalInforVideoIntgration] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (statusPlatforms.data[0]?.platform == "nuvemshop") {
            setStatusNuvemshop(true)
        } else {
            setStatusNuvemshop(false)
        }

    }, [statusPlatforms])

    useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])



    return (
        <Body>
            <Modal />
            <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={"https://www.youtube.com/embed/HtYaGyKHqSg"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                {/* <AvaliacaoPopUpProvider> */}
                {statusPlatforms.loading ?
                    <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                        <LoadingLogo />
                    </div> :
                    <Contents>

                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                            <label style={{ alignItems: "center", margin: "0px" }} >
                                {translation.alerts.integracoes}
                            </label>
                            <span style={{}}>
                                <label onClick={() => { setVisibleModalInfor(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                    {translation.alerts.verComoFunciona}
                                </label>
                            </span>
                        </AlertCloseBoss>

                        <Container>
                            <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                        </Container>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <label style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}                                >
                                {translation.integrations.naoEncontrou}
                                <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=5511941475967") }} style={{ marginLeft: "5px", cursor: "pointer" }}> {translation.integrations.cliqueAqui} </strong>
                                {translation.integrations.falarSuporte}
                            </label>
                        </div>

                    </Contents>
                }
                {/* </AvaliacaoPopUpProvider> */}
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
