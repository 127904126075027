import { combineReducers } from "redux";

import reducerLogin from "../modules/login/redux/reducers/reducerLogin";
import reducerRegister from "../modules/login/redux/reducers/reducerRegister";
import reducerPassword from "../modules/login/redux/reducers/reducerPassword";
import reducerControlLanguage from "../modules/login/redux/reducers/reducerControlLanguage";

import reducerIntegration from "../modules/integrations/redux/reducers/reducerIntegration";
import reducerSync from "../modules/integrations/redux/reducers/reducerSync";
import reducerControlPlatform from "../modules/integrations/redux/reducers/reducerControlPlatform";
import reducerGetPlatforms from "../modules/integrations/redux/reducers/reducerGetPlatforms";
import reducerDeleteIntegration from "../modules/integrations/redux/reducers/reducerDeleteIntegration";
import reducerControlSyncProducts from "../modules/integrations/redux/reducers/reducerControlSyncProducts";



import reducerPayments from "../modules/assinatura/redux/reducers/reducerPayments";

import reducerUpdateAccount from "../modules/account/redux/reducers/reducerUpdateAccount";
import reducerDeleteAccount from "../modules/account/redux/reducers/reducerDeleteAccount";

import reducerDashboard from "../modules/dashboard/redux/reducers/reducerDashboard";
import reducerQueryHelp from "../modules/dashboard/redux/reducers/reducerQueryHelp";

import reducerAdminList from "../modules/admin/redux/reducers/reducerAdminList";
import reducerAdminDeleteCard from "../modules/admin/redux/reducers/reducerAdminDeleteCard";
import reducerAdminDebug from "../modules/admin/redux/reducers/reducerAdminDebug";
import reducerAdminDropi from "../modules/admin/redux/reducers/reducerAdminDropi";
import reducerAdminAddTime from "../modules/admin/redux/reducers/reducerAdminAddTime";
import reducerAdminXpatch from "../modules/admin/redux/reducers/reducerAdminXpatch";

import reducerUpdateConfig from "../modules/thema/redux/reducers/reducerUpdateConfig";
import reducerGetConfig from "../modules/thema/redux/reducers/reducerGetConfig";
import reducerControlConfig from "../modules/thema/redux/reducers/reducerControlConfig";

import reducerControlEdit from "../modules/thema/redux/reducers/reducerControlEdit";

import reducerListNotify from "../components/Header/redux/reducers/reducerListNotify";
import reducerControlMenu from "../components/Header/redux/reducers/reducerControlMenu";
import reducerGetInforAccount from "../components/Header/redux/reducers/reducerGetInforAccount";

import reducerTokenNuvemshop from "../routers/redux/reducers/reducerTokenNuvemshop";


const rootReducer = combineReducers({
    reducerLogin,
    reducerPassword,
    reducerRegister,
    reducerIntegration,
    reducerSync,
    reducerGetPlatforms,
    reducerControlPlatform,
    reducerControlSyncProducts,
    reducerDeleteIntegration,
    reducerPayments,
    reducerUpdateAccount,
    reducerDeleteAccount,
    reducerDashboard,
    reducerQueryHelp,
    reducerAdminList,
    reducerAdminDeleteCard,
    reducerAdminDebug,
    reducerAdminDropi,
    reducerGetConfig,
    reducerControlConfig,
    reducerUpdateConfig,
    reducerListNotify,
    reducerAdminAddTime,
    reducerAdminXpatch,
    reducerControlMenu,
    reducerControlEdit,
    reducerTokenNuvemshop,
    reducerGetInforAccount,
    reducerControlLanguage,
})

export default rootReducer;