
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { put, call, } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, PUT_CUSTOM, POST_CUSTOM } from "../../../../services/api";


export function* updateConfig(action) {
    console.log("updateConfig - action", action)
    yield put({ type: "CONFIG_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT_CUSTOM, { endpoint: `/configurations/${action.payload.id}`, data: action.payload })
    //console.log("RESULT: ", result)
    if (result === false) {
        yield put({ type: "CONFIG_UPDATE_ERROR" })
    } else {
        yield put({ type: "CONFIG_UPDATE_SUCCESS", payload: result.data })

    }
}


export function* getConfig() {

    yield put({ type: "CONFIG_GET_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: `/configurations` })
    if (result === false) {
        yield put({ type: "CONFIG_GET_ERROR" })
    } else {
        yield put({ type: "CONFIG_GET_SUCCESS", payload: result.data.data })

        console.log("payload: result.data.data", result?.data?.data)

        // let dataControl = result?.data?.data


        // dataControl.widget_button_icon = "heart"
        // dataControl.widget_button_text = "Favoritar"
        // dataControl.widget_button_text_font_size = "14px"
        // dataControl.widget_button_text_align = "center"
        // dataControl.widget_button_text_bolt = 0
        // dataControl.widget_button_text_italic = 0
        // dataControl.widget_button_text_underline = 0
        // dataControl.widget_button_text_color = "#ffffff"
        // dataControl.widget_button_border_radius = "8"
        // dataControl.widget_button_color_background = "#46459B"
        // dataControl.widget_button_color_icon = "#ffffff"
        // dataControl.widget_box_type = "floating"
        // dataControl.widget_box_icon = "heart"
        // dataControl.widget_box_position = "center-bottom"
        // dataControl.widget_box_color_background = "#46459B"
        // dataControl.widget_box_color_icon = "#ffffff"

        // dataControl.widget_box_text = "Favoritos"
        // dataControl.widget_box_text_font_size = "14px"
        // dataControl.widget_box_text_align = "center"
        // dataControl.widget_box_text_bolt = 0
        // dataControl.widget_box_text_italic = 0
        // dataControl.widget_box_text_underline = 0
        // dataControl.widget_box_text_color = "#ffffff"



        //dataControl?.radioTime = "forever"
        //dataControl["radioTime"] = dataControl?.days_time == 0 ? "forever" : dataControl?.days_time < 7 ? "day" : "week"
        //dataControl["qtdTime"] = dataControl?.days_time > 6 ? dataControl?.days_time / 7 : dataControl?.days_time
        yield put({ type: "CONFIG_CONTROL_SET", payload: result?.data?.data })
    }
}

