import nuvemshopImg from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import { getTranslation } from "../../../../translations";

const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

export const platforms = {
    nuvemshop: {
        image: nuvemshopImg,
        message: translation.integrations.dataCard.nuvemshop.message,
        title:  translation.integrations.nuvemshop,
        titleColor: "#2C3357",
        action: JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ? `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`: `https://www.tiendanube.com/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`,
        body: {
            title:translation.integrations.dataCard.nuvemshop.title,
            steps: [
                translation.integrations.dataCard.nuvemshop.steps1,
                translation.integrations.dataCard.nuvemshop.steps2,
                translation.integrations.dataCard.nuvemshop.steps3
            ]
        }

    },
}