import { useEffect, useState } from "react";
import {
  Container,
  BarActions,
  BoxOptions,
  BoxActions,
  Option,
  Action,
  ContentsAlert,
  Box,
  BoxPreview,
  Tela,
  TelaContents,
  TelaPreview,
  Space,
  BoxImg,
  ContainerPreview,
  PreviewTitle,
  PreviewBox,
  Voltar,
  BoxCards,
  Proximo,
  PreviewPaginate,
  BarMenuStore,
  ContainerStore,
  IconBoxStore,
  ListStoreMenu,
  Count,
} from "./styled";
import {
  AiFillGift,
  AiFillHeart,
  AiFillStar,
  AiOutlineGift,
  AiOutlineHeart,
} from "react-icons/ai";
import {
  RiHeart2Fill,
  RiHeart2Line,
  RiQuestionnaireFill,
  RiUserLine,
} from "react-icons/ri";
import { useSelector } from "react-redux";
import AlertClose from "../../../../components/Alert-Close";
import imgBackground from "../../../../assets/imgs/preview-background.png";
import imgPreviewTela from "../../../../assets/imgs/preview-tela.svg";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import imgBasePreviewTela from "../../../../assets/imgs/base-preview.svg";

import imgPreviewLeft from "../../../../assets/imgs/preview-left.svg";
import imgPreviewRight from "../../../../assets/imgs/preview-right.svg";
import { BsCart3, BsSuitHeart, BsSuitHeartFill } from "react-icons/bs";
import { getTranslation } from "../../../../translations";
import { PiHeartBreak, PiHeartBreakFill } from "react-icons/pi";

const PreviewIconBox = () => {
  const config = useSelector((state) => state.reducerControlConfig);
  const translation = getTranslation(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );

  const getPosition = () => {
    switch (config.widget_box_position) {
      case "left-top":
        return {
          left: `${config.widget_box_space_h}px`,
          top: `${config.widget_box_space_v}px`,
        };
      case "center-top":
        return {
          left: "calc(50% - 25px)",
          top: `${config.widget_box_space_v}px`,
        };
      case "right-top":
        return {
          right: `${config.widget_box_space_h}px`,
          top: `${config.widget_box_space_v}px`,
        };
      case "left-center":
        return {
          left: `${config.widget_box_space_h}px`,
          top: "calc(50% - 25px)",
        };
      case "center-center":
        return {
          left: "calc(50% - 25px)",
          top: "calc(50% - 25px)",
        };
      case "right-center":
        return {
          right: `${config.widget_box_space_h}px`,
          top: "calc(50% - 25px)",
        };
      case "left-bottom":
        return {
          left: `${config.widget_box_space_h}px`,
          bottom: `${config.widget_box_space_v}px`,
        };
      case "center-bottom":
        return {
          left: "calc(50% - 25px)",
          bottom: `${config.widget_box_space_v}px`,
        };
      case "right-bottom":
        return {
          right: `${config.widget_box_space_h}px`,
          bottom: `${config.widget_box_space_v}px`,
        };
      default:
        break;
    }
  };

  const getFontSize = () => {
    console.log("config.widget_box_size", config.widget_box_size);
    if (config.widget_box_size > 100) {
      return "16px";
    } else if (config.widget_box_size > 80) {
      return "14px";
    } else if (config.widget_box_size > 50) {
      return "12px";
    } else {
      return "10px";
    }
  };

  return (
    <BoxPreview>
      <Container img={imgBackground} font={config.font}>
        <Box backgroundColor={config.background_color_primary}>
          {/* <img src={imgPreviewTela} /> */}
          <Tela>
            <TelaContents>
              <TelaPreview>
                <BarMenuStore>
                  <label>{translation.thema.barraListaFavoritos.suaLoja}</label>
                  <span>
                    <label>Buscar</label>
                  </span>
                  <ListStoreMenu
                    animation={config.widget_box_type == "fixed" ? true : false}
                  >
                    {config.widget_box_type == "fixed" && (
                      <div
                        style={{
                          backgroundColor: config.widget_box_color_background,
                        }}
                      >
                        {config.widget_box_icon == "heart" ? (
                          <AiOutlineHeart
                            color={config.widget_box_color_icon}
                            size={20}
                          />
                        ) : config.widget_box_icon == "heart02" ? (
                          <BsSuitHeart
                            color={config.widget_box_color_icon}
                            size={20}
                          />
                        ) : config.widget_box_icon == "heart03" ? (
                          <RiHeart2Line
                            color={config.widget_box_color_icon}
                            size={20}
                          />
                        ) : config.widget_box_icon == "heart04" ? (
                          <PiHeartBreak
                            color={config.widget_box_color_icon}
                            size={20}
                          />
                        ) : (
                          <AiOutlineGift
                            color={config.widget_box_color_icon}
                            size={20}
                          />
                        )}
                        <label
                          style={{
                            color: config.widget_box_text_color,
                            fontWeight:
                              config.widget_box_text_bolt == 1
                                ? "bold"
                                : "initial",
                            textDecoration:
                              config.widget_box_text_underline == 1
                                ? "underline"
                                : "initial",
                            fontStyle:
                              config.widget_box_text_italic == 1
                                ? "italic"
                                : "initial",
                            fontSize: "12px",
                          }}
                        >
                          {config.widget_box_text}
                        </label>
                      </div>
                    )}
                    <div>
                      <RiUserLine color={"#fff"} size={20} />
                      <label>
                        {translation.thema.barraListaFavoritos.minhaConta}
                      </label>
                    </div>
                    <div>
                      <BsCart3 color={"#fff"} size={20} />
                      <label>
                        {translation.thema.barraListaFavoritos.meuCarrinho}
                      </label>
                    </div>
                  </ListStoreMenu>
                </BarMenuStore>
                <ContainerStore>
                  {config.widget_box_type == "floating" && (
                    <IconBoxStore
                      size={
                        config.widget_box_size ? config.widget_box_size : 40
                      }
                      background={config.widget_box_color_background}
                      style={getPosition()}
                    >
                      <div>
                        {config.widget_box_icon == "heart" ? (
                          <span>
                            <AiFillHeart
                              color={config.widget_box_color_icon}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                            />
                            <Count
                              fontSize={getFontSize()}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                              background={config.widget_box_color_background}
                              color={config.widget_box_color_icon}
                            >
                              10
                            </Count>
                          </span>
                        ) : config.widget_box_icon == "heart02" ? (
                          <span>
                            <BsSuitHeartFill
                              color={config.widget_box_color_icon}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                            />
                            <Count
                              fontSize={getFontSize()}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                              background={config.widget_box_color_background}
                              color={config.widget_box_color_icon}
                            >
                              10
                            </Count>
                          </span>
                        ) : config.widget_box_icon == "heart03" ? (
                          <span>
                            <RiHeart2Fill
                              color={config.widget_box_color_icon}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                            />
                            <Count
                              fontSize={getFontSize()}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                              background={config.widget_box_color_background}
                              color={config.widget_box_color_icon}
                            >
                              10
                            </Count>
                          </span>
                        ) : config.widget_box_icon == "heart04" ? (
                          <span>
                            <PiHeartBreakFill
                              color={config.widget_box_color_icon}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                            />
                            <Count
                              fontSize={getFontSize()}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                              background={config.widget_box_color_background}
                              color={config.widget_box_color_icon}
                            >
                              10
                            </Count>
                          </span>
                        ) : (
                          <span>
                            <AiFillGift
                              color={config.widget_box_color_icon}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                            />
                            <Count
                              fontSize={getFontSize()}
                              size={
                                config.widget_box_size
                                  ? config.widget_box_size / 2
                                  : 24
                              }
                              background={config.widget_box_color_background}
                              color={config.widget_box_color_icon}
                            >
                              10
                            </Count>
                          </span>
                        )}
                      </div>
                    </IconBoxStore>
                  )}
                </ContainerStore>
              </TelaPreview>
            </TelaContents>
            <Space />
            <BoxImg>
              <img width={200} src={imgBasePreviewTela} />
            </BoxImg>
          </Tela>
        </Box>
      </Container>
    </BoxPreview>
  );
};

export default PreviewIconBox;
