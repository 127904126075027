import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AlertSave, Box, BoxActions, BoxForm, ButtonDefault, ButtonSave, EditCustom, ErrorMessage, FormCustom, InputCustomItem, InputCustomItemColor, SelectCustom, Tooltips } from './styled';
import { FaRegQuestionCircle } from "react-icons/fa";
import PreviewTheme from "../../components/preview-theme";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import ModalInforVideo from '../../../../components/modal-infor-video';
import IconEdit from '../../../../assets/imgs/icon-edit.svg';
import IconClose from '../../../../assets/imgs/close-white.svg';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';
import { isSave } from '../../../../utils/custom';
import { getTranslation } from '../../../../translations';

const FormTema = () => {

    const [visibleModalInforTema, setVisibleModalInforTema] = useState(false)

    const edit = useSelector(state => state.reducerControlEdit)
    const dispatch = useDispatch()

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        console.log("supoer teste", configControl)
    }, [configControl])


    return (
        <div style={{ width: "100%" }}>
            <ModalInforVideo visible={visibleModalInforTema} setVisibleModal={setVisibleModalInforTema} video={"https://www.youtube.com/embed/1o8_iwGt7NU"} />
            <FormCustom>
                <Box>
                    <PreviewTheme />
                </Box>

            </FormCustom>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <label
                    style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}                >
                    {translation.thema.preview.problemasTemplate}
                    <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=5511941475967") }} style={{ marginLeft: "5px", cursor: "pointer" }}> {translation.thema.preview.clique} </strong>
                </label>
            </div>
        </div>

    )

}
export default FormTema