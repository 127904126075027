import { all, takeLatest } from "redux-saga/effects";

import { logar, concluirLogarPlus, register, password } from "../modules/login/redux/sagas/sagaLogin";
import { integrate, platforms, deleteIntegration } from "../modules/integrations/redux/sagas/sagaIntegration";
import { listPayments } from "../modules/assinatura/redux/sagas/sagaPayment";
import { updateAccount, deleteAccount, } from "../modules/account/redux/sagas/sagaAccount";
import { getInfor } from "../modules/dashboard/redux/sagas/sagaDashboard";
import { getList, deleteCard, debug, dropi, addMonths, addXpatch } from "../modules/admin/redux/sagas/sagaAdmin";
import { updateConfig, getConfig, } from "../modules/thema/redux/sagas/sagaConfig";
import { listNotify, getInforAccount } from "../components/Header/redux/sagas/sagaNotify";
import { getTokenNuvemshop } from "../routers/redux/sagas/sagaToken";


export default function* rootSagas() {

    yield all([
        takeLatest('SAGA_LOGAR_LOGIN', logar),
        takeLatest('SAGA_LOGAR_PLUS_CONCLUIR', concluirLogarPlus),
        takeLatest('SAGA_REGISTER_LOGIN', register),
        takeLatest('SAGA_PASSWORD_LOGIN', password),

        takeLatest('SAGA_INTEGRATE_INTEGRATION', integrate),
        takeLatest('SAGA_GET_PLATFORMS_INTEGRATION', platforms),
        takeLatest('SAGA_DELETE_INTEGRATION', deleteIntegration),

        takeLatest('SAGA_LIST_PAYMENTS', listPayments),

        takeLatest('SAGA_UPDATE_ACCOUNT', updateAccount),
        takeLatest('SAGA_DELETE_ACCOUNT', deleteAccount),

        takeLatest('SAGA_INFOR_DASHBOARD', getInfor),

        takeLatest('SAGA_LIST_ADMIN', getList),
        takeLatest('SAGA_DELETE_CARD_ADMIN', deleteCard),
        takeLatest('SAGA_DEBUG_ADMIN', debug),
        takeLatest('SAGA_ADD_TIME_ADMIN', addMonths),
        takeLatest('SAGA_DROPI_ADMIN', dropi),
        takeLatest('SAGA_XPATCH_ADMIN', addXpatch),

        takeLatest('SAGA_GET_CONFIG', getConfig),
        takeLatest('SAGA_UPDATE_CONFIG', updateConfig),


        takeLatest('SAGA_LIST_NOTIFY', listNotify),
        takeLatest('SAGA_GET_INFOR_ACCOUNT', getInforAccount),

        takeLatest('SAGA_GET_TOKEN_NUVEMSHOP', getTokenNuvemshop),

    ])
}