import { Container, SectionLogo, ItemMenu, SectionMenu, TooltipItem } from "./styled";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useBurgerMenu } from '../../hooks/useBurgerMenu'
import Logo from "../../assets/imgs/logo/SuperFavoritoLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import iconCores from "./../../assets/imgs/icons-sidebar/submenu-cores.svg";
import iconCoresAtivo from "./../../assets/imgs/icons-sidebar/submenu-cores-ativo.svg";
import iconAjustes from "./../../assets/imgs/icons-sidebar/submenu-ajustes.svg";
import iconAjustesAtivo from "./../../assets/imgs/icons-sidebar/submenu-ajustes-ativo.svg";
import { getTranslation } from "../../translations";

const SideBar = () => {

    const { burgerMenu, setBurgerMenu } = useBurgerMenu()
    const menuOpen = useSelector(state => state.reducerControlMenu)
    const selectedMenu = useLocation();
    const history = useHistory();
    const [visibleSubMenuConfig, setVisibleSubMenuConfig] = useState(true)
    const edit = useSelector(state => state.reducerControlEdit)
    const dispatch = useDispatch()

    //const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    useEffect(() => {
        if (selectedMenu.pathname == "/personalizar/tema" || selectedMenu.pathname == "/personalizar" || selectedMenu.pathname == "/personalizar/email") {
            setVisibleSubMenuConfig(true)
        } else {
            setVisibleSubMenuConfig(false)
        }
    }, [selectedMenu.pathname])



    function handleClick(path) {
        window.innerWidth <= 768 ? setBurgerMenu(true) : setBurgerMenu(false)
        history.push(path)
    }




    useEffect(() => {
        console.log("menuOpen", menuOpen)
    }, [menuOpen])



    return (

        <Container menuOpen={menuOpen.isOpen}>
            <SectionLogo style={{cursor: "pointer"}} menuOpen={menuOpen.isOpen}>
                <img style={{ cursor: "pointer" }} src={Logo} onClick={() => { history.push("/") }} />
            </SectionLogo>
            <SectionMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/tema" || selectedMenu.pathname === "/personalizar/avancado" ? true : false}>
                    {menuOpen.isOpen && (selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/tema" || selectedMenu.pathname === "/personalizar/avancado") ?
                        <svg onClick={() => { handleClick("/personalizar") }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                            <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001V3.02001Z" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.91 4.15002C15.2416 5.32749 15.8699 6.40009 16.7349 7.26507C17.5999 8.13005 18.6725 8.75843 19.85 9.09002" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> :
                        <svg onClick={() => { handleClick("/personalizar") }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001V3.02001Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.91 4.15002C15.2416 5.32749 15.8699 6.40009 16.7349 7.26507C17.5999 8.13005 18.6725 8.75843 19.85 9.09002" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                    <label onClick={() => { handleClick("/personalizar") }}> {translation.sidebar.personalizar}</label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/personalizar" ? true : false}>
                            {translation.sidebar.personalizar}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
                    {menuOpen.isOpen && selectedMenu.pathname === "/integracoes" ?
                        <svg onClick={() => { handleClick("/integracoes") }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.01 2.92L18.91 5.54C20.61 6.29 20.61 7.53 18.91 8.28L13.01 10.9C12.34 11.2 11.24 11.2 10.57 10.9L4.67002 8.28C2.97002 7.53 2.97002 6.29 4.67002 5.54L10.57 2.92C11.24 2.62 12.34 2.62 13.01 2.92V2.92Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> :
                        <svg onClick={() => { handleClick("/integracoes") }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.01 2.92L18.91 5.54C20.61 6.29 20.61 7.53 18.91 8.28L13.01 10.9C12.34 11.2 11.24 11.2 10.57 10.9L4.67002 8.28C2.97002 7.53 2.97002 6.29 4.67002 5.54L10.57 2.92C11.24 2.62 12.34 2.62 13.01 2.92V2.92Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                    <label onClick={() => { handleClick("/integracoes") }}>
                        {translation.sidebar.integracoes}
                    </label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
                            {translation.sidebar.integracoes}
                        </TooltipItem>
                    </span>
                </ItemMenu>
                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
                    {menuOpen.isOpen && selectedMenu.pathname === "/pagamentos" ?
                        <svg onClick={() => { handleClick("/pagamentos") }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="#ffffff" stroke- stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.5 12.41V7.84C2.5 6.65 3.23 5.59 4.34 5.17L12.28 2.17C12.5677 2.06158 12.8774 2.0246 13.1825 2.06222C13.4876 2.09985 13.7791 2.21095 14.0318 2.386C14.2846 2.56104 14.491 2.79481 14.6335 3.06722C14.7761 3.33963 14.8503 3.64256 14.85 3.95V7.75M7 12H14M22.559 13.97V16.03C22.559 16.58 22.119 17.03 21.559 17.05H19.599C18.519 17.05 17.529 16.26 17.439 15.18C17.379 14.55 17.619 13.96 18.039 13.55C18.409 13.17 18.919 12.95 19.479 12.95H21.559C22.119 12.97 22.559 13.42 22.559 13.97V13.97Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> :
                        <svg onClick={() => { handleClick("/pagamentos") }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.5 12.41V7.84C2.5 6.65 3.23 5.59 4.34 5.17L12.28 2.17C12.5677 2.06158 12.8774 2.0246 13.1825 2.06222C13.4876 2.09985 13.7791 2.21095 14.0318 2.386C14.2846 2.56104 14.491 2.79481 14.6335 3.06722C14.7761 3.33963 14.8503 3.64256 14.85 3.95V7.75M7 12H14M22.559 13.97V16.03C22.559 16.58 22.119 17.03 21.559 17.05H19.599C18.519 17.05 17.529 16.26 17.439 15.18C17.379 14.55 17.619 13.96 18.039 13.55C18.409 13.17 18.919 12.95 19.479 12.95H21.559C22.119 12.97 22.559 13.42 22.559 13.97V13.97Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                    <label onClick={() => { handleClick("/pagamentos") }}>
                        {translation.sidebar.assinatura}
                    </label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
                            {translation.sidebar.assinatura}
                        </TooltipItem>
                    </span>
                </ItemMenu>

                <ItemMenu menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false}>
                    {menuOpen.isOpen && selectedMenu.pathname === "/ajuda" ?
                        <svg onClick={() => { handleClick("/ajuda") }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.46005 18.49V15.57C5.46005 14.6 6.22005 13.73 7.30005 13.73C8.27005 13.73 9.14005 14.49 9.14005 15.57V18.38C9.14005 20.33 7.52005 21.95 5.57005 21.95C3.62005 21.95 2.00005 20.32 2.00005 18.38V12.22C1.89005 6.59999 6.33005 2.04999 11.95 2.04999C17.57 2.04999 22 6.59999 22 12.11V18.27C22 20.22 20.38 21.84 18.43 21.84C16.48 21.84 14.86 20.22 14.86 18.27V15.46C14.86 14.49 15.62 13.62 16.7 13.62C17.67 13.62 18.54 14.38 18.54 15.46V18.49" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> :
                        <svg onClick={() => { handleClick("/ajuda") }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.46005 18.49V15.57C5.46005 14.6 6.22005 13.73 7.30005 13.73C8.27005 13.73 9.14005 14.49 9.14005 15.57V18.38C9.14005 20.33 7.52005 21.95 5.57005 21.95C3.62005 21.95 2.00005 20.32 2.00005 18.38V12.22C1.89005 6.59999 6.33005 2.04999 11.95 2.04999C17.57 2.04999 22 6.59999 22 12.11V18.27C22 20.22 20.38 21.84 18.43 21.84C16.48 21.84 14.86 20.22 14.86 18.27V15.46C14.86 14.49 15.62 13.62 16.7 13.62C17.67 13.62 18.54 14.38 18.54 15.46V18.49" stroke="black" stroke-width="1.5" stroke-opacity="0.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                    <label onClick={() => { handleClick("/ajuda") }}>
                        {translation.sidebar.ajuda}
                    </label>
                    <span>
                        <label >.</label>
                        <TooltipItem menuOpen={menuOpen.isOpen} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false} >
                            {translation.sidebar.ajuda}
                        </TooltipItem>
                    </span>
                </ItemMenu>
            </SectionMenu>
        </Container >




















        // <Container id="container__sidemenu" style={burgerMenu ? { transform: 'translateX(-250px)' } : { transform: 'translateX(0px)' }}>
        //     <SectionLogo>
        //         <span id="box-animation">
        //             {/* <img style={{ cursor: "pointer" }} src={Logo} onClick={() => { history.push("/") }} /> */}
        //             <video style={{ cursor: "pointer" }} id="animation" width="75" muted onClick={() => handleClick("/")} >
        //                 <source src={animation} type="video/webm" />
        //             </video>
        //         </span>
        //     </SectionLogo>
        //     <SectionMenu>
        //         <TitleMenu>MENU</TitleMenu>
        //         <ItemMenu onClick={() => { handleClick("/") }} selectedMenu={selectedMenu.pathname === "/" ? true : false}>
        //             <RiDashboardFill size={icon.size} color={icon.color} />
        //             <label>Dashboard</label>
        //         </ItemMenu>
        //         <ItemMenu onClick={() => { handleClick('/avaliacoes') }} selectedMenu={selectedMenu.pathname === "/avaliacoes" ? true : false}>
        //             <AiFillStar size={icon.size} color={icon.color} />
        //             <label>Avaliações</label>
        //             {listNotify.data?.data?.newReview > 0 &&
        //                 <span>
        //                     <label>{listNotify.data?.data?.newReview}</label>
        //                 </span>}
        //         </ItemMenu>
        //         <ItemMenu onClick={() => { handleClick("/perguntas") }} selectedMenu={selectedMenu.pathname === "/perguntas" ? true : false}>
        //             <RiQuestionnaireFill size={icon.size} color={icon.color} />
        //             <label>Perguntas</label>
        //             {listNotify.data?.data?.newAsk > 0 &&
        //                 <span>
        //                     <label>{listNotify.data?.data?.newAsk}</label>
        //                 </span>}
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/produtos") }} selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}>
        //             <FaBoxOpen size={icon.size} color={icon.color} />
        //             <label>Meus Produtos</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/cupons") }} selectedMenu={selectedMenu.pathname === "/cupons" ? true : false}>
        //             <AiFillTag size={icon.size} color={icon.color} />
        //             <label>Cupons</label>
        //         </ItemMenu>

        //         {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url != null &&
        //             <ItemMenu
        //                 onClick={() => {
        //                     if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
        //                         JSON.parse(localStorage.getItem("depoimentos@login")).integration.url.includes("http") ?
        //                             window.open(`${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}`) :
        //                             window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}`)
        //                 }}
        //             >
        //                 <IoStorefrontSharp size={icon.size} color={icon.color} />
        //                <label>Minha Loja</label>
        //             </ItemMenu>}

        //     </SectionMenu>

        //     <SectionMenu> 
        //         <TitleMenu>CONFIGURAÇÕES</TitleMenu>
        //         <ItemMenu onClick={() => { handleClick("/integracoes") }} selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}>
        //             <FaLayerGroup size={icon.size} color={icon.color} />
        //             <label>Integrações</label>
        //         </ItemMenu>
        //         <ItemMenuOptions>
        //             <BoxMenu visible={visibleSubMenuConfig} onClick={() => { setVisibleSubMenuConfig(!visibleSubMenuConfig) }}>
        //                 <BsFillGearFill size={icon.size} color={icon.color} />
        //                 <label>Personalizar</label>
        //                 <span>
        //                     {visibleSubMenuConfig ? <IoIosArrowUp size={18} color={icon.color} /> : <IoIosArrowDown size={18} color={icon.color} />}
        //                 </span>
        //             </BoxMenu>
        //             <BoxSubMenu visible={visibleSubMenuConfig} selectedMenu={selectedMenu.pathname}>
        //                 <BoxSubMenulabel onClick={() => { handleClick("/personalizar/email") }} selectedMenu={selectedMenu.pathname === "/personalizar/email" ? true : false} >E-mail</BoxSubMenulabel>
        //                 <BoxSubMenulabel onClick={() => { handleClick("/personalizar/tema") }} selectedMenu={selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/tema" ? true : false} >Página produto</BoxSubMenulabel>
        //                 {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" && <BoxSubMenulabel onClick={() => { handleClick("/personalizar/home") }} selectedMenu={selectedMenu.pathname === "/personalizar/home" ? true : false} >Página Inicial</BoxSubMenulabel>}

        //             </BoxSubMenu>
        //         </ItemMenuOptions>

        //         <ItemMenu onClick={() => { handleClick("/pagamentos") }} selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}>
        //             <FaPiggyBank size={icon.size} color={icon.color} />
        //             <label>Assinatura</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/passo-a-passo") }} selectedMenu={selectedMenu.pathname === "/passo-a-passo" ? true : false}>
        //             <TiStarOutline size={icon.size} color={icon.color} />
        //             <label>Passo a Passo</label>
        //         </ItemMenu>

        //         <ItemMenu onClick={() => { handleClick("/ajuda") }} selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false}>
        //             <BiSupport size={icon.size} color={icon.color} />
        //             <label>Ajuda</label>
        //         </ItemMenu>
        //     </SectionMenu>
        // </Container>
    )
}

export default SideBar
