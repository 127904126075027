import styled from "styled-components";

export const Container = styled.div`
  //background-color: yellow ;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;

  height: 710px;
  overflow-y: scroll;
`;

export const ButtonHelp = styled.div`
  background-color: #121212;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 60px;
  border-radius: 4px;
  justify-content: center;
  //border: 1px dashed #0D6EFD;
  cursor: pointer;

  label {
    font-size: 12px;
    cursor: pointer;
    color: #fff;
  }

  img {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
`;

export const BoxActive = styled.div`
  display: flex;
  background-color: #fff;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;

  label {
    font-size: 10px;
    font-weight: 600;
    color: #878787;
  }

  div {
    display: flex;
    gap: 10px;
  }
`;

export const ItemActive = styled.div`
  display: flex;
  background-color: #f7f8fa;
  height: 40px;
  flex: 1;
  border-radius: 8px;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  label {
    display: flex;
    color: #050505;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }

  span {
    display: flex;
    width: 28px;
    height: 16px;
    background-color: ${(props) => (props.active ? "#4745ED" : "#7C7C7D")};
    align-items: center;
    justify-content: ${(props) => (!props.active ? "left" : "right")};
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;

    div {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 2px;
    }
  }
`;

export const BoxSizeImg = styled.div`
  display: flex;
  background-color: #fff;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;

  label {
    font-size: 10px;
    font-weight: 600;
    color: #878787;
  }

  div {
    display: flex;
    gap: 10px;
  }
`;

export const OptionImg = styled.div`
  display: flex;
  background-color: ${(props) => (props.active ? "#4745ED" : " #F7F8FA")};
  height: 36px;
  flex: 1;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  label {
    color: ${(props) => (props.active ? "#ffffff" : "#8C9196")};
    cursor: pointer;
  }
`;

export const BoxHistory = styled.div`
  display: flex;
  background-color: #fff;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;

  label {
    font-size: 10px;
    font-weight: 600;
    color: #878787;
  }

  div {
    display: flex;
    //gap: 2px;
  }
`;

export const SelectCustom = styled.select`
  display: flex;
  width: 50px;
  //background-color: red;
  border: 1px solid #d3dae3;
  padding: 0px 5px;
  margin-right: 5px;
  border-radius: 8px;
  cursor: pointer;
`;

export const RadioCustom = styled.div`
  display: flex;
  background-color: #f7f8fa;
  align-items: center;
  padding: 5px;
  //border-radius: 8px ;
  height: 38px;
  cursor: pointer;

  label {
    font-size: 11px;
    margin-right: 5px;
    cursor: pointer;
  }

  span {
    width: 14px;
    height: 14px;
    background-color: ${(props) => (props.active ? "#4745ED" : "#7C7C7D")};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;

export const BoxPage = styled.div`
  display: flex;
  background-color: #fff;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;

  label {
    font-size: 10px;
    font-weight: 600;
    color: #878787;
  }

  div {
    display: flex;
    //gap: 2px;
    justify-content: space-between;
  }
`;

export const OptionPage = styled.div`
  display: flex;
  background-color: #f7f8fa;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  height: 38px;
  width: 140px;
  cursor: pointer;

  label {
    font-size: 11px;
    margin-right: 5px;
    cursor: pointer;
  }
`;

export const CheckCustom = styled.div`
  display: flex;
  background-color: ${(props) => (props.active ? "#4745ED" : "transparent")};
  width: 14px;
  height: 14px;
  justify-content: center !important;
  align-items: center;
  border-radius: 3px;
  border: ${(props) => (!props.active ? "1px solid #7C7C7D" : "none")};

  span {
    display: flex;
    //background-color: purple ;
  }
`;

export const BoxSlider = styled.div`
  display: flex;
  background-color: #fff;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;

  label {
    font-size: 10px;
    font-weight: 600;
    color: #878787;
  }

  div {
    display: flex;
    //gap: 2px;
    justify-content: space-between;
  }
`;

export const ButtonSave = styled.div`
  background-color: #00803b;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 36px;
  border-radius: 4px;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
`;

export const ButtonReset = styled.div`
  //background-color: red ;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 36px;
  border-radius: 4px;
  justify-content: center;
  border: 1px solid #000000;
  cursor: pointer;

  label {
    font-size: 12px;
    cursor: pointer;
    color: #000;
  }

  img {
    width: 14px;
    height: 16px;
  }
`;
export const Title = styled.label`
  //background-color: blueviolet ;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  margin: 20px 0px;

  label {
    display: flex;
    flex: 1;
    //background-color: green ;
    justify-content: center;
    font-size: 16px;
    color: #000;
    margin-left: 5px;
    cursor: pointer;
  }

  span {
    //background-color: red;
    cursor: pointer;
  }
`;

export const MenuOption = styled.div`
  //background-color: red ;
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
`;

export const InputTextButton = styled.input`
  background-color: #f7f7f7;
  display: flex;
  outline: none;
  border: none;
  padding: 0px 10px;
  font-size: 12px;
  height: 30px;
  border-radius: 4px;
`;

export const ItemOption = styled.label`
  font-size: 14px;
  display: flex;
  flex: 1;
  justify-content: center;
  border-bottom: ${(props) => (props.active ? "3px solid #FFCC00" : "none")};
  padding-bottom: 5px;
  color: ${(props) => (props.active ? "#050505" : "inicial")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "500" : "inicial")};
  height: ${(props) => (props.active ? "30px" : "33px")};
`;

export const ItemLine = styled.div`
  display: flex;
  padding: 10px;
  background-color: #fff;
  flex-direction: column;
  border-radius: 4px;

  label {
    font-size: 14px;
  }
`;

export const ItemLineColor = styled.div`
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 4px;
  align-items: center;

  label {
    font-size: 14px;
    flex: 1;
  }
`;
export const IconOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
`;
export const IconOptionContainer = styled.div`
  display: flex;
  gap: 10px;
`;
