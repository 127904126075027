import styled from "styled-components";

export const Box = styled.div`
  background-color:  ${props => props.backgroundColor};
  padding: 20px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  min-height: 630px;
`

export const BoxPreview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  //width: 400px;
  //max-width: calc(100vw - 60px);
  //background-color: blue;
  //background-color: red;
  width: 100%;

  @media(max-width: 768px) {
    max-width: calc(100vw - 60px);
  }
  

`

export const Container = styled.div`

  display: flex ;
  flex: 100% ;
  //margin-left: 10px ;
  flex-direction:  column;
  padding: 40px;
  border-radius: 5px;
  /* margin-left: 350px ; */
  font-family: ${props => props.font} ; 

  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
  background-size: 60%;

  height: 100% ;

  //background-color: yellow ;
  //width: calc(100vw - 30px) ;
  width: 100% ;

  @media(max-width: 1100px) {
     //background-color: red ;
  }

`

export const Tela = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  //background-color: #ffffff;
  height: 450px;
  flex-direction: column;
  
`
export const TelaContents = styled.div`
  display: flex;
  background-color: #fff;
  min-width: 100%;
  min-height: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  border-radius: 20px 20px 0px 0px;
`
export const TelaPreviewSlider = styled.div`
  display: flex;
`

export const TelaPreview = styled.div`
  display: flex;
  background-color: #F3F3F3;
  height: 100%;
  min-height: 336px;
  min-width: calc(100% - 60px);
  margin: 26px 30px;
  border-radius: 0px;
  align-items: flex-end;
`

export const Space = styled.div`
  display: flex;
  min-height: 58px;
  min-width: 100%;
  background-color: #fff;
  border-top: 2px solid #F3F3F3;
  margin: 0px;
  border-radius: 0px 0px 20px 20px;
  border-bottom: 1px solid #F3F3F3;
`

export const BoxImg = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: center;
`

