import React, { useState, useEffect } from "react";
import {
  AlignOptions,
  AlignOptionsItem,
  ButtonHelp,
  ButtonReset,
  ButtonSave,
  Container,
  IconOption,
  IconOptionContainer,
  SelectCustom,
  SliderCustom,
  Title,
} from "./styled";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from "react-redux";
import IconReset from "../../../../assets/imgs/icon-reset.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  BarConfigTitle,
  BoxTitle,
  CounterItemsCircle,
  ItemBar,
  ItemColor,
  ItemColorCustom,
  TextAreaCustom,
} from "../bar-config-avancado/styled";

import IconTextLeft from "../../../../assets/imgs/text-left.svg";
import IconTextRight from "../../../../assets/imgs/text-right.svg";
import IconTextCenter from "../../../../assets/imgs/text-center.svg";
import IconTextBold from "../../../../assets/imgs/text-bold.svg";
import IconTextUnderline from "../../../../assets/imgs/text-underline.svg";
import IconTextItalic from "../../../../assets/imgs/text-italic.svg";
import { ItemLine, ItemLineColor } from "../bar-config-button/styled";
import { getTranslation } from "../../../../translations";
import { AiFillGift, AiFillHeart } from "react-icons/ai";
import { BsSuitHeartFill } from "react-icons/bs";
import { RiHeart2Fill } from "react-icons/ri";
import { PiHeartBreakFill } from "react-icons/pi";

const BarConfigBox = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.reducerGetConfig);
  const configControl = useSelector((state) => state.reducerControlConfig);
  const translation = getTranslation(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );
  const history = useHistory();
  const handleIconClick = (iconType) => {
    dispatch({
      type: "CONFIG_CONTROL_SET",
      payload: { widget_box_icon: iconType },
    });
  };
  const saveTheme = () => {
    dispatch({
      type: "SAGA_UPDATE_CONFIG",
      payload: {
        id: config.data?.id,
        widget_box_type: configControl.widget_box_type,
        widget_box_icon: configControl.widget_box_icon,
        widget_box_position: configControl.widget_box_position,
        widget_box_color_background: configControl.widget_box_color_background,
        widget_box_color_icon: configControl.widget_box_color_icon,
        widget_box_text: configControl.widget_box_text,
        widget_box_text_font_size: configControl.widget_box_text_font_size,
        widget_box_text_align: configControl.widget_box_text_align,
        widget_box_text_bolt: configControl.widget_box_text_bolt ? 1 : 0,
        widget_box_text_italic: configControl.widget_box_text_italic ? 1 : 0,
        widget_box_text_underline: configControl.widget_box_text_underline
          ? 1
          : 0,
        widget_box_text_color: configControl.widget_box_text_color,

        widget_box_size: configControl.widget_box_size,
        widget_box_space_h: configControl.widget_box_space_h,
        widget_box_space_v: configControl.widget_box_space_v,
      },
    });
  };

  const getDefaultTheme = () => {
    const themaDefault = {
      id: config.data?.id,
      widget_box_type: "floating",
      widget_box_icon: "heart",
      widget_box_position: "center-bottom",
      widget_box_color_background: "#4745ED",
      widget_box_color_icon: "#ffffff",
      widget_box_text: "Favoritos",
      widget_box_text_font_size: "9px",
      widget_box_text_align: "center",
      widget_box_text_bolt: 0,
      widget_box_text_italic: 0,
      widget_box_text_underline: 0,
      widget_box_text_color: "#ffffff",

      widget_box_size: "50",
      widget_box_space_h: "20",
      widget_box_space_v: "20",
    };
    dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault });
  };

  useEffect(() => {
    console.log("configControl", configControl);
  }, [configControl]);

  return (
    <Container>
      <Title>
        <span
          onClick={() => {
            history.push("/personalizar");
          }}
        >
          <IoIosArrowBack />
        </span>
        <label>{translation.thema.barraCaixaFavoritos.titulo}</label>
      </Title>

      <ItemLine style={{ gap: "4px" }}>
        <label>{translation.thema.barraCaixaFavoritos.itemTipo}</label>
        <SelectCustom
          style={{ width: "100%", height: "40px", fontSize: "12px" }}
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_box_type: e.target.value },
            });
          }}
        >
          <option
            value="floating"
            selected={
              configControl.widget_box_type == "floating" ? true : false
            }
          >
            {translation.thema.barraCaixaFavoritos.flutuante}
          </option>
          <option
            value="fixed"
            selected={configControl.widget_box_type == "fixed" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.fixo}
          </option>
        </SelectCustom>
      </ItemLine>

      <ItemLine style={{ gap: "10px" }}>
        <label>{translation.thema.barraCaixaFavoritos.itemCores} </label>
        <ItemLineColor>
          <label>{translation.thema.barraCaixaFavoritos.corFundo}</label>
          <ItemColor>
            <span>
              <input
                type={"color"}
                value={configControl.widget_box_color_background}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_color_background: e.target.value },
                  })
                }
              />
              <label>
                {configControl?.widget_box_color_background?.toUpperCase()}
              </label>
            </span>
          </ItemColor>
        </ItemLineColor>

        <ItemLineColor>
          <label>{translation.thema.barraCaixaFavoritos.corIcone}</label>
          <ItemColor>
            <span>
              <input
                type={"color"}
                value={configControl.widget_box_color_icon}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_color_icon: e.target.value },
                  })
                }
              />
              <label>
                {configControl?.widget_box_color_icon?.toUpperCase()}
              </label>
            </span>
          </ItemColor>
        </ItemLineColor>
      </ItemLine>

      <ItemLine style={{ gap: "4px" }}>
        <label>{translation.thema.barraCaixaFavoritos.itemIcone}</label>
        <IconOptionContainer>
          <IconOption
            onClick={() => handleIconClick("heart")}
            selected={configControl.widget_box_icon === "heart"}
            style={{
              borderColor:
                configControl.widget_box_icon === "heart"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <AiFillHeart
              color={
                configControl.widget_box_icon === "heart"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart02")}
            selected={configControl.widget_box_icon === "heart02"}
            style={{
              borderColor:
                configControl.widget_box_icon === "heart02"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <BsSuitHeartFill
              color={
                configControl.widget_box_icon === "heart02"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart03")}
            selected={configControl.widget_box_icon === "heart03"}
            style={{
              borderColor:
                configControl.widget_box_icon === "heart03"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <RiHeart2Fill
              color={
                configControl.widget_box_icon === "heart03"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("heart04")}
            selected={configControl.widget_box_icon === "heart04"}
            style={{
              borderColor:
                configControl.widget_box_icon === "heart04"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <PiHeartBreakFill
              color={
                configControl.widget_box_icon === "heart04"
                  ? "#000000"
                  : "#dcdcdc"
              }
            />
          </IconOption>
          <IconOption
            onClick={() => handleIconClick("gift")}
            selected={configControl.widget_box_icon === "gift"}
            style={{
              borderColor:
                configControl.widget_box_icon === "gift"
                  ? "#000000"
                  : "#dcdcdc",
            }}
          >
            <AiFillGift
              color={
                configControl.widget_box_icon === "gift" ? "#000000" : "#dcdcdc"
              }
            />
          </IconOption>
        </IconOptionContainer>
        {/* <SelectCustom
          style={{ width: "100%", height: "40px", fontSize: "12px" }}
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_box_icon: e.target.value },
            });
          }}
        >
          <option
            value="heart"
            selected={configControl.widget_box_icon == "heart" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.coracao}
          </option>
          <option
            value="heart02"
            selected={configControl.widget_box_icon == "heart02" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.coracao2}
          </option>
          <option
            value="heart03"
            selected={configControl.widget_box_icon == "heart03" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.coracao3}
          </option>
          <option
            value="heart04"
            selected={configControl.widget_box_icon == "heart04" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.coracao4}
          </option>
          <option
            value="gift"
            selected={configControl.widget_box_icon == "gift" ? true : false}
          >
            {translation.thema.barraCaixaFavoritos.presente}
          </option>
        </SelectCustom> */}
      </ItemLine>

      <ItemLine>
        <label style={{ marginBottom: "8px" }}>Ajustes: </label>
        <SliderCustom
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_box_size: e.target.value },
            });
          }}
        >
          <label>Tamanho</label>
          <input
            type="range"
            value={configControl.widget_box_size}
            id="volume"
            name="volume"
            min="40"
            max="120"
          />
        </SliderCustom>

        <SliderCustom
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_box_space_v: e.target.value },
            });
          }}
        >
          <label>Espaço vertical</label>
          <input
            type="range"
            value={configControl.widget_box_space_v}
            id="volume"
            name="volume"
            min="10"
            max="100"
          />
        </SliderCustom>

        <SliderCustom
          onChange={(e) => {
            dispatch({
              type: "CONFIG_CONTROL_SET",
              payload: { widget_box_space_h: e.target.value },
            });
          }}
        >
          <label>Espaço horizontal</label>
          <input
            type="range"
            value={configControl.widget_box_space_h}
            id="volume"
            name="volume"
            min="10"
            max="100"
          />
        </SliderCustom>
      </ItemLine>

      <BoxTitle>
        <label style={{ fontSize: "14px" }}>
          {translation.thema.barraCaixaFavoritos.itemTexto}
        </label>
        <div style={{ position: "relative" }}>
          <TextAreaCustom
            maxLength="20"
            rows={3}
            placeholder={"Texto do botão"}
            name={"title"}
            value={configControl.widget_box_text}
            onChange={(e) =>
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_text: e.target.value },
              })
            }
          />
          <BarConfigTitle>
            <select
              onChange={(e) => {
                dispatch({
                  type: "CONFIG_CONTROL_SET",
                  payload: { widget_box_text_font_size: e.target.value },
                });
              }}
            >
              <option
                value={"9px"}
                selected={
                  configControl.widget_box_text_font_size == "9px"
                    ? true
                    : false
                }
              >
                9px
              </option>
              <option
                value={"10px"}
                selected={
                  configControl.widget_box_text_font_size == "10px"
                    ? true
                    : false
                }
              >
                10px
              </option>
              <option
                value={"11px"}
                selected={
                  configControl.widget_box_text_font_size == "11px"
                    ? true
                    : false
                }
              >
                11px
              </option>
              <option
                value={"12px"}
                selected={
                  configControl.widget_box_text_font_size == "12px"
                    ? true
                    : false
                }
              >
                12px
              </option>
              <option
                value={"14px"}
                selected={
                  configControl.widget_box_text_font_size == "14px"
                    ? true
                    : false
                }
              >
                14px
              </option>
              <option
                value={"16px"}
                selected={
                  configControl.widget_box_text_font_size == "16px"
                    ? true
                    : false
                }
              >
                16px
              </option>
              <option
                value={"18px"}
                selected={
                  configControl.widget_box_text_font_size == "18px"
                    ? true
                    : false
                }
              >
                18px
              </option>
            </select>

            <ItemColorCustom color={configControl.widget_box_text_color}>
              <label for={"testes"}>A</label>
              <div />
              <input
                id={"testes"}
                type={"color"}
                value={configControl.widget_box_text_color}
                onChange={(e) =>
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_text_color: e.target.value },
                  })
                }
              />
            </ItemColorCustom>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_box_text_align == "left" ? true : false
              }
            >
              <img
                src={IconTextLeft}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_text_align: "left" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_box_text_align == "center" ? true : false
              }
            >
              <img
                src={IconTextCenter}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_text_align: "center" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              style={{ visibility: "hidden" }}
              active={
                configControl.widget_box_text_align == "right" ? true : false
              }
            >
              <img
                src={IconTextRight}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: { widget_box_text_align: "right" },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_box_text_bolt ||
                configControl.widget_box_text_bolt == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextBold}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_box_text_bolt: !configControl.widget_box_text_bolt,
                    },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_box_text_italic ||
                configControl.widget_box_text_italic == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextItalic}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_box_text_italic:
                        !configControl.widget_box_text_italic,
                    },
                  });
                }}
              />
            </ItemBar>

            <ItemBar
              active={
                configControl.widget_box_text_underline ||
                configControl.widget_box_text_underline == 1
                  ? true
                  : false
              }
            >
              <img
                src={IconTextUnderline}
                onClick={(e) => {
                  dispatch({
                    type: "CONFIG_CONTROL_SET",
                    payload: {
                      widget_box_text_underline:
                        !configControl.widget_box_text_underline,
                    },
                  });
                }}
              />
            </ItemBar>
          </BarConfigTitle>
          <CounterItemsCircle
            porc={`${Math.round(
              (configControl.widget_box_text?.length / 20) * 100
            )}%`}
          >
            <label>{20 - configControl.widget_box_text?.length}</label>
          </CounterItemsCircle>
        </div>
      </BoxTitle>

      <ItemLine style={{ gap: "5px" }}>
        <label>{translation.thema.barraCaixaFavoritos.posicao}</label>
        <AlignOptions>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "left-top" },
              });
            }}
            active={
              configControl.widget_box_position == "left-top" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "left-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "center-top" },
              });
            }}
            active={
              configControl.widget_box_position == "center-top" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "center-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                right: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "right-top" },
              });
            }}
            active={
              configControl.widget_box_position == "right-top" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "right-top"
                    ? "#ffffff"
                    : "#000000",
                top: "5px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "left-center" },
              });
            }}
            active={
              configControl.widget_box_position == "left-center" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "left-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "center-center" },
              });
            }}
            active={
              configControl.widget_box_position == "center-center"
                ? true
                : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "center-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                left: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "right-center" },
              });
            }}
            active={
              configControl.widget_box_position == "right-center" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "right-center"
                    ? "#ffffff"
                    : "#000000",
                top: "35px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "left-bottom" },
              });
            }}
            active={
              configControl.widget_box_position == "left-bottom" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "left-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                left: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "center-bottom" },
              });
            }}
            active={
              configControl.widget_box_position == "center-bottom"
                ? true
                : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "center-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                left: "35px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
          <AlignOptionsItem
            onClick={() => {
              dispatch({
                type: "CONFIG_CONTROL_SET",
                payload: { widget_box_position: "right-bottom" },
              });
            }}
            active={
              configControl.widget_box_position == "right-bottom" ? true : false
            }
          >
            <div
              style={{
                backgroundColor:
                  configControl.widget_box_position == "right-bottom"
                    ? "#ffffff"
                    : "#000000",
                bottom: "5px",
                right: "5px",
                position: "absolute",
                borderRadius: "50%",
                minWidth: "10px",
                minHeight: "10px",
                display: "flex",
              }}
            />
          </AlignOptionsItem>
        </AlignOptions>
      </ItemLine>

      <ButtonHelp>
        <img src={IconBossVideo} />
        <label>{translation.thema.acoes.ajuda}</label>
        <img style={{ width: "24px", height: "24px" }} src={IconVideo} />
      </ButtonHelp>

      <ButtonSave onClick={() => saveTheme()}>
        {translation.thema.acoes.salvar}
      </ButtonSave>

      <ButtonReset onClick={() => getDefaultTheme()}>
        <label>{translation.thema.acoes.restaurarPadrao}</label>
        <img src={IconReset} />
      </ButtonReset>
    </Container>
  );
};

export default BarConfigBox;
