import styled from "styled-components";

export const Box = styled.div`
  background-color:  ${props => props.backgroundColor};
  padding: 20px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  min-height: 630px;
`

export const BoxPreview = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  //width: 400px;
  //max-width: calc(100vw - 60px);
  //background-color: blue;
  //background-color: red;
  width: 100%;

  @media(max-width: 768px) {
    max-width: calc(100vw - 60px);
  }
  

`

export const Container = styled.div`

  display: flex ;
  flex: 100% ;
  //margin-left: 10px ;
  flex-direction:  column;
  padding: 40px;
  border-radius: 5px;
  /* margin-left: 350px ; */
  font-family: ${props => props.font} ; 

  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
  background-size: 60%;

  height: 100% ;

  //background-color: yellow ;
  //width: calc(100vw - 30px) ;
  width: 100% ;

  @media(max-width: 1100px) {
     //background-color: red ;
  }

`

export const Tela = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  //background-color: #ffffff;
  height: 450px;
  flex-direction: column;
  
`
export const TelaContents = styled.div`
  display: flex;
  background-color: #fff;
  min-width: 100%;
  min-height: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  border-radius: 20px 20px 0px 0px;
`

export const TelaPreview = styled.div`
  display: flex;
  background-color: #F3F3F3;
  height: 100%;
  min-height: 336px;
  min-width: calc(100% - 60px);
  margin: 26px 30px;
  border-radius: 0px;
  align-items: flex-end !important;
  flex-direction: column;
  background-color: red;
`

export const Space = styled.div`
  display: flex;
  min-height: 58px;
  min-width: 100%;
  background-color: #fff;
  border-top: 2px solid #F3F3F3;
  margin: 0px;
  border-radius: 0px 0px 20px 20px;
  border-bottom: 1px solid #F3F3F3;
`

export const BoxImg = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: center;
`

export const ContainerPreview = styled.div`
  display: flex;
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  height: max-content;
  width: 100%;
  height: 300px;
  background-color: yellow;
`

export const PreviewTitle = styled.div`
  display: flex;
  //background-color: violet;
  height: 40px;
  align-items: center;
  justify-content: ${props => props.align};

  label{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    color: ${props => props.colorTitle};
    font-weight: ${props => props.bold ? "bold" : "initial"};
    text-decoration:  ${props => props.underline ? "underline" : "initial"};
    font-style: ${props => props.italic ? "italic" : "initial"};
    font-size: ${props => props.fontSize};
    font-family: ${props => props.font};

    span{
      font-size: 8px;
      color: #0047FF;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
`

export const PreviewBox = styled.div`
  display: flex;
  //background-color: green;
  //height: 125px;
  align-items: center;
  //margin: 10px 0px ;
  margin: ${props => props.visible ? "10px 0px" : "10px 20px"};
  padding-bottom: ${props => props.visible ? "10px" : "10px"};
`

export const Voltar = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: violet;
  margin: 0px 20px;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }

`

export const Proximo = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  margin: 0px 20px;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }
  
`

export const BoxCards = styled.div`
  display: flex;
  //background-color: violet;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  span{
    background: #E0E0E0;
    border-radius: 2px;
    min-width: 60px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    label{
      font-size: 9px;
      font-weight: bold;
    }
  }

`

export const PreviewPaginate = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  label{
    font-size: 8px;
  }

`

export const BoxProductStore = styled.div`
  display: flex;
  //background-color: red;
  flex: 1;
  padding: 20px;
  align-items: center;
  justify-content: center;

  img{
    width: 200px;
  }

`

export const BoxInforProductStore = styled.div`
  display: flex;
  //background-color: yellow;
  width: 300px;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
`
export const NameProduct = styled.label`
  display: flex;
  font-size: 24px;
  color: #c4c4c4;
  font-weight: 500;
`

export const PriceProduct = styled.label`
  display: flex;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: bold;
`

export const BoxPurchase = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`

export const QtdPurchase = styled.label`
  display: flex;
  border: 1px solid #dcdcdc;
  height: 40px;
  width: 80px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-around;
  letter-spacing: 5px;
`

export const ButtonPurchase = styled.label`
  display: flex;
  background-color: #c4c4c4;
  width: 150px;
  height: 40px;
  padding: 10px 0px;
  justify-content: center;
  font-size: 14px;
  border-radius: 20px;
  color: #fff;
`

export const ButtonFavorite = styled.div`
  display: flex;
  background-color: ${props => props.background};
  margin-top: 10px;
  height: 40px;
  border-radius: ${props => props.radius + "px"};
  align-items: center;
  justify-content: ${props => props.align};
  gap: 10px;
  position: relative;

  padding: 0px 10px;

  label{
    color: ${props => props.color};
    font-style: ${props => props.italic == 1 ? "italic" : "initial"};
    text-decoration: ${props => props.underline == 1 ? "underline" : "initial"};
    font-weight: ${props => props.bolt == 1 ? "bold" : "initial"};
    font-size: ${props => props.size};
  }


  ::before, ::after{
    content: "";
    //display: block;
    display: none;
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    border: 2px solid #46459B44;
    border-radius: 8px;
    animation: animatePulse 0.8s linear infinite;

      @keyframes animatePulse {
      0%{
        transform: scale(1);
        opacity: 0;
      }

      50%{
        opacity: 1;
      }

      100%{
        transform: scale(1.1);
        opacity: 0;
      }
    }
  }

  
`

export const ArrowBox = styled.div`
  display: flex;
  //background-color: red;
  width: 80px;
  align-items: center;
  justify-content: center;

  span{
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid gray;
    justify-content: center;
    align-items: center;
  }
`

export const SwiperBox = styled.div`
  display: flex;
  //background-color: #f0000011;
  flex: 1;
  justify-content: space-between;
`

export const CardItemProduct = styled.div`
  display: flex;
  //background-color: #80808022;
  flex-direction: column;
  width: 230px;
  height: 300px;
  
`

export const NameBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px;

  label{
    font-size: 14px;
    text-align: center;
  }
`

export const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p{
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
    text-align: center;
    color: #46459B;
  }

  label{
    font-size: 12px;
    text-align: center;
  }
`

export const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: #46459B11;
  margin: 20px;
  border-radius: 8px;
  position: relative;

  img{
    width: 100px;
    object-fit: contain;
    height: 150px;
    //background-color: red;
  }

  span{
    display: flex;
    position: absolute;
    //background-color: #46459B;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
`

export const ContainerStoreNew = styled.div`
  display: flex;
  //height: 100px;
  //background-color: red;
  width: 100%;
  //background-color: yellow;


`

export const TelaPreviewNew = styled.div`
  display: flex;
  background-color: #F3F3F3;
  height: 500px;
  min-height: 336px;
  min-width: calc(100% - 60px);
  margin: 26px 30px;
  border-radius: 0px;
  align-items: flex-end !important;
  //flex-direction: column;
  //background-color: red;
`