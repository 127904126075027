import { useEffect, useState } from 'react'
import { Container, Box, BoxPreview, Tela, TelaContents, TelaPreview, Space, BoxImg, ContainerPreview, PreviewTitle, PreviewBox, Voltar, BoxCards, Proximo, PreviewPaginate, TelaPreviewSlider } from './styled'
import { AiFillGift, AiFillHeart, } from "react-icons/ai";
import { useSelector } from 'react-redux';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import imgBasePreviewTela from "../../../../assets/imgs/base-preview.svg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { getTranslation } from '../../../../translations';
import Slider from '../slider';
import { FaTrash } from 'react-icons/fa';
import SliderModel02 from '../slider-model-02';
import SliderModel03 from '../slider-model-03';
import SliderModel04 from '../slider-model-04';

const PreviewTema = () => {

  const config = useSelector(state => state.reducerControlConfig)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);



  return (
    <BoxPreview>
      <Container img={imgBackground} font={config.font} >
        <Box backgroundColor={config.background_color_primary}>
          <Tela>
            <TelaContents>
              <TelaPreview>
                <Slider />
                {/* {config.widget_layout == "modelo01" && <Slider />}

                {config.widget_layout == "modelo02" && <SliderModel02 />}

                {config.widget_layout == "modelo03" && <SliderModel03 />}

                {config.widget_layout == "modelo04" && <SliderModel04 />} */}

              </TelaPreview>


              {/*<TelaPreview>
                <ContainerPreview backgroundColor={config.color_widget} >
                  <PreviewTitle font={config.font} fontSize={config.title_font_size} colorTitle={config.color_font_title} italic={config.title_italic} bold={config.title_bolt} underline={config.title_underline} align={config.title_align}>
                    <label>
                      {config.title}
                    </label>
                  </PreviewTitle>

                  <PreviewBox visible={config.slider_scroll}>
                    <Voltar visible={config.slider_scroll} >
                      <span style={{ backgroundColor: `${config.color_scroll_background}` }}>
                        <IoIosArrowBack color={config.color_scroll} />
                      </span>
                    </Voltar>
                    <BoxCards>
                      {Array(8).fill(
                        <span style={{ position: "relative" }}>
                          <label style={{ visibility: "hidden" }}>{config.size_img}</label>
                          <div style={{ display: "flex", borderRadius: "50%", width: "20px", height: "20px", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "5px", right: "5px", backgroundColor: `${config.list_color_background_icon}` }} >
                            {config.list_type_icon == "heart" ?
                              <AiFillHeart color={`${config.list_color_icon}`} size={10} /> :
                              <AiFillGift color={`${config.list_color_icon}`} size={10} />}
                          </div>
                        </span>
                      )}
                    </BoxCards>
                    <Proximo visible={config.slider_scroll}>
                      <span style={{ backgroundColor: `${config.color_scroll_background}` }}>
                        <IoIosArrowForward color={config.color_scroll} />
                      </span>
                    </Proximo>
                  </PreviewBox>
                  <PreviewPaginate visible={config.slider_paginate}>
                    <label style={{ color: `${config.color_font_title}` }}>8 {translation.thema.preview.produtos}</label>
                  </PreviewPaginate>
                </ContainerPreview>
              </TelaPreview>*/}
            </TelaContents>
            <Space />
            <BoxImg>
              <img width={200} src={imgBasePreviewTela} />
            </BoxImg>
          </Tela>
        </Box>
      </Container>

    </BoxPreview>

  )
}

export default PreviewTema