import { BoxBarApps, UserDropdownSubMenuInfor, UserDropdownSubMenu, Container, SectionOptions, UserDropdown, TooltipMenu, BoxMenu, BoxApps, BoxFixxed } from "./styled";
import { AiOutlineLogout, AiOutlineCopy } from "react-icons/ai";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { useBurgerMenu } from '../../hooks/useBurgerMenu'
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AlertClose from "../Alert-Close";
import iconMenuNew from "../../assets/imgs/icon-menu.svg";
import { getTranslation } from "../../translations";

const Header = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const { burgerMenu, setBurgerMenu } = useBurgerMenu()
    const menuOpen = useSelector(state => state.reducerControlMenu)
    const selectedMenu = useLocation();
    const history = useHistory();
    const [visibleMenu, setVisibleMenu] = useState(false)
    const [visibleMenuNotify, setVisibleMenuNotify] = useState(false)
    const dispatch = useDispatch()

    const closeMenu = (event) => { setVisibleMenu(false) };

    const closeMenuNotify = (event) => { setVisibleMenuNotify(false) };

    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }
    }, [visibleMenu])

    useEffect(() => {
        if (visibleMenuNotify) {
            window.addEventListener('mouseup', closeMenuNotify);
            return () => { window.removeEventListener('mouseup', closeMenuNotify); };
        }
    }, [visibleMenuNotify])

    const copy = () => {
        navigator.clipboard.writeText(JSON.parse(localStorage.getItem("depoimentos@login")).user.chave_empreender)
        toast.success("Chave empreender copiada.")
    }

    function handleBurgerMenuClick() {
        burgerMenu ? setBurgerMenu(false) : setBurgerMenu(true)
    }

    useEffect(() => {
        if (localStorage.getItem("depoimentos@login")) {
        } else {
            history.push("/login")
        }
    }, [])

    let userInformations = localStorage.getItem('depoimentos@login');

    // useEffect(function () {
    //     try {
    //         let toolbarTimeout = setInterval(() => {
    //             let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;

    //             let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

    //             if (token) {
    //                 window?.empreenderPlusApiInstancia({
    //                     caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub", //OBRIGATORIO
    //                     afiliado: "", //OPCIONAL
    //                     insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
    //                     app: "SUPERFAV", //code do app - OBRIGATORIO
    //                     headers: {
    //                         Authorization: tokenType + " " + token,
    //                         "Content-Type": "application/json",
    //                     },
    //                 });

    //                 clearInterval(toolbarTimeout);
    //             }
    //         }, 250);
    //     } catch (e) {
    //         console.error("empreenderPlusApiInstancia - error", e);
    //     }
    // }, [selectedMenu, userInformations, window?.initProdutosEmpreenderTopMenu]);

    useEffect(function () {
        try {
            let infosParsed = JSON.parse(userInformations);
            if (infosParsed?.user?.id_user_plus) {
                window.empreenderPlusRegistrarConversao(infosParsed.user.id_user_plus); // passar o id user plus
            }
        } catch (err) { }
    }, [userInformations]);

    // useEffect(function () {
    //     try {
    //         let infosParsed = JSON.parse(userInformations);

    //         if (infosParsed?.user?.id_user_plus && infosParsed?.access_token?.token) {
    //             let user_access_token = infosParsed.access_token.type + " " + infosParsed.access_token.token;

    //             if (user_access_token) {
    //                 window.initProdutosEmpreenderTopMenu({
    //                     caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub",
    //                     headers: {
    //                         'Authorization': user_access_token,
    //                         'Content-Type': "application/json"
    //                     }
    //                 });
    //             }
    //         }
    //     } catch (e) { console.log(e) }
    // }, [selectedMenu, userInformations]);

    // useEffect(function () {
    //     try {
    //         let infosParsed = JSON.parse(userInformations);
    //         if (infosParsed?.user?.id_user_plus) {
    //             window.empreenderPlusRegistrarConversao(infosParsed.user.id_user_plus); // passar o id user plus
    //         }
    //     } catch (err) { }
    // }, [userInformations]);


    const handleMenu = () => {
        dispatch({ type: "HEADER_CONTROL_MENU", payload: { isOpen: !menuOpen.isOpen } })
    }


    return (
        <>

            {/* <BoxFixxed>
                <div className="insert-toolbar-plus" />
            </BoxFixxed> */}

            {props.children}
            <Container menuOpen={menuOpen.isOpen}>
                <BoxMenu>
                    <img onClick={() => { handleMenu() }} src={iconMenuNew} />
                </BoxMenu>

                {/* <div className="insert-toolbar-plus"
                    style={{
                        position: "relative",
                        backgroundColor: "red",
                        minHeight: "50px",
                        maxWidth: "500px",
                        top: "100px"
                    }}
                /> */}


                <SectionOptions>
                    <UserDropdown onClick={() => { setVisibleMenu(true) }} >
                        <span>
                            <span>
                                {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name[0]}
                            </span>
                        </span>
                        <UserDropdownSubMenu visible={visibleMenu}>
                            <UserDropdownSubMenuInfor>
                                <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name}</label>
                                <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email}</label>
                                {
                                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url != null &&
                                    <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}</label>
                                }
                            </UserDropdownSubMenuInfor>
                            <hr />
                            <span onClick={() => { copy() }} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", }}>
                                <p style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "0px", marginLeft: "-10px" }}>
                                    <label style={{ display: "flex", alignItems: "center", marginLeft: "5px", color: "#20D489" }} >
                                        {translation.header.menu.chave}:
                                        <TooltipMenu>
                                            <FiHelpCircle size={17} color={"#20D489"} />
                                            <label>Esse é o código de indentificação desse aplicativo.</label>
                                        </TooltipMenu>
                                    </label>
                                </p>
                            </span>
                            <span onClick={() => { copy() }}>
                                <label style={{ width: "100%", display: "flex", alignItems: "center", }}>
                                    <AiOutlineCopy style={{ display: "flex" }} size={19} color={"gray"} />
                                    <label style={{ textTransform: "uppercase", marginLeft: "7px" }} >{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}</label>
                                </label>
                            </span>
                            {process.env.REACT_APP_HABILITAR_PLUS ?
                                <span onClick={() => { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }}>
                                    <img alt="Empreender Plus" width="16" src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/landingpage/ap8n31063713.svg" />
                                    <label style={{ marginLeft: "8px" }}>Empreender Plus</label>
                                </span>
                                : ''}

                            <span onClick={() => { history.push("/conta") }}>
                                <FiSettings style={{ display: "flex" }} size={20} color={"gray"} />
                                <label style={{ marginLeft: "7px" }}>{translation.header.menu.minhaConta}</label>
                            </span>

                            <span style={{ marginBottom: "0px" }} onClick={() => { localStorage.removeItem("depoimentos@login"); history.push("/login") }}>
                                <AiOutlineLogout style={{ display: "flex" }} size={20} color={"gray"} />
                                <label style={{ marginLeft: "7px" }}>{translation.header.menu.sair}</label>
                            </span>
                        </UserDropdownSubMenu>
                    </UserDropdown>
                </SectionOptions>
            </Container>
            {/* <AlertClose margin={"20px 30px 0px 280px"}
                visible={
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" &&
                        new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.updated_at) < new Date("2022-07-01") ?
                        true : false
                }
                close={false}>
                <label>
                    Sua integração com a Shopify pode está apresentando problemas. Por favor, exclua a integração atual e integre novamente.
                </label>
            </AlertClose> */}
        </>
    )
}

export default Header
