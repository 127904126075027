
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { parse } from "date-fns";

const Code = () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const route = useRouteMatch()

    const controlIntegrationSteps = useSelector(state => state.reducerControlIntegrationSteps)

    useEffect(() => {
        //console.log("controlIntegrationSteps", controlIntegrationSteps)
    }, [controlIntegrationSteps])


    useEffect(() => {

        //("teste route", route)
        //console.log("teste location", location)
        let shop = location.search.substring(location.search.indexOf("shop=") + 5)
        let url = location.search.substring(location.search.indexOf("url=") + 4)
        let clientId = location.search.substring(location.search.indexOf("client_id=") + 10).split("&")[0]
        let clientSecret = location.search.substring(location.search.indexOf("client_secret=") + 14).split("&")[0]
        let basic = location.search.substring(location.search.indexOf("basic=") + 6).split("&")[0]
        let store = location.search.substring(location.search.indexOf("store=") + 6).split("&")[0]
        let token = location.search.substring(location.search.indexOf("token=") + 6).split("&")[0]
        let chaveLojaIntegrada = location.search.substring(location.search.indexOf("chaveLojaIntegrada=") + 19).split("&")[0]
        //console.log("teste shop -> ", teste.split("&")[0])
        let tokenCartpanda = location.search.substring(location.search.indexOf("tokenCartpanda=") + 15).split("&")[0]

        let state = location.search.substring(location.search.indexOf("state=") + 6).split("&")[0]
        let instanceId = location.search.substring(location.search.indexOf("instanceId=") + 11).split("&")[0]
        let code = location.search.substring(location.search.indexOf("code=") + 5).split("&")[0]

        //console.log("teste integração shopify route.params", route.params.type)
        const urlParams = new URLSearchParams(window.location.search);

        if (route.params.type == "nuvemshop") {
            console.log("route.params.type == nuvemshop")
            if (localStorage.getItem("nuvemshop@code") && localStorage.getItem("nuvemshop@code") != null) {
                console.log("IF ", localStorage.getItem("nuvemshop@code"))
                let data = JSON.parse(localStorage.getItem("nuvemshop@code"))
                data.typeIntegration = "nuvemshop"
                data.log = "IF"
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: data
                })
            } else {
                console.log("else", localStorage.getItem("nuvemshop@code"))
                dispatch({
                    type: "CONTROL_PLATFORM_SUCCESS", payload: {
                        typeIntegration: "nuvemshop",
                        code: location.search.substring(6, location.search.length),
                        log: "else"
                    }
                })
            }
            history.push('/integracoes');
        } else {
            console.log("route.params.type != nuvemshop")
            dispatch({
                type: "CONTROL_PLATFORM_SUCCESS", payload: {
                    typeIntegration: route.params.type,
                    code: route.params.type == "loja_integrada" ? chaveLojaIntegrada.split("?")[0] : location.search.substring(6, location.search?.length),
                    shop: shop.split("&")[0],
                    url: url.replace(/^https?:\/\//, ''),
                    client_id: clientId,
                    client_secret: clientSecret,
                    basic: basic,
                    store: store,
                    token: tokenCartpanda.length !== 0 ? tokenCartpanda.split("?")[0] : token,
                    state: state,
                    instanceId: instanceId,
                    codeWix: code,

                }
            })
            //alert("teste:", JSON.parse(localStorage.getItem("isIntegrationSteps")))
            if (JSON.parse(localStorage.getItem("isIntegrationSteps")) == true) {
                localStorage.setItem("isIntegrationSteps", false);
                history.push('/passo-a-passo');
            }
            else history.push('/integracoes');
        }

    }, [])

    return (
        <div>
        </div>
    )
}

export default Code
