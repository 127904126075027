import { intervalToDuration, format, fromUnixTime } from "date-fns";

export const formatDate = (date, formatMask) => {
    return format(new Date(date), formatMask)
}

export const formatTimestamp = (date, formatMask) => {
    let completeDate = fromUnixTime(date)
    return format(completeDate, formatMask)
}

export const interval = (cretaed_at) => {
    let result = "";
    let dates = intervalToDuration({
        start: new Date(cretaed_at),
        end: new Date()
    })

    //console.log("Dates: ", dates.minutes)

    if (dates.years == 1) return result += dates.years + " Ano "
    else if (dates.years > 0 ) return result += dates.years + " Anos "

    if (dates.months == 1) return result += dates.months + " Mês "
    else if (dates.months > 0 ) return result += dates.months + " Meses "

    if (dates.days == 1) return result += dates.days + " Dia "
    else if (dates.days > 0 ) return result += dates.days + " Dias "

    if (dates.hours == 1) return result += dates.hours + " Hora "
    else if (dates.hours > 0 ) return result += dates.hours + " Horas "

    if (dates.minutes == 1 || dates.minutes == 0) return result += dates.minutes + " Minuto "
    else if (dates.minutes > 0 ) return result += dates.minutes + " Minutos "

    return result
}