import styled from "styled-components";

export const ContainerPreview = styled.div`
  display: flex;
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  height: max-content;
  width: 100%;
  padding: 10px 0px;

  //background-color: red;
`

export const TitleText = styled.label`
  margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    color: ${props => props.colorTitle};
    font-weight: ${props => props.bold ? "bold" : "initial"};
    text-decoration:  ${props => props.underline ? "underline" : "initial"};
    font-style: ${props => props.italic ? "italic" : "initial"};
    font-size: ${props => props.fontSize};
    font-family: ${props => props.font};
    flex: 1;
    //background-color: red;

    span{
      font-size: 8px;
      color: #0047FF;
      text-decoration: underline;
      margin-left: 5px;
    }
`


export const PreviewTitle = styled.div`
  display: flex;
  //background-color: violet;
  height: 40px;
  align-items: center;
  justify-content: ${props => props.align};

`

export const PreviewBox = styled.div`
  display: flex;
  //background-color: green;
  //height: 125px;
  align-items: center;
  //margin: 10px 0px ;
  margin: ${props => props.visible ? "10px 0px" : "10px 20px"};
  padding-bottom: ${props => props.visible ? "10px" : "10px"};
`

export const Voltar = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: violet;
  margin: 0px 20px;
  cursor: pointer;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }

`

export const Proximo = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  margin: 0px 20px;
  cursor: pointer;

  span{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    //border: 1px solid red;
  }
  
`

export const BoxCards = styled.div`
  display: flex;
  //background-color: violet;
  gap: 10px;
  justify-content: space-between;
  width: ${props => props.large ? "calc(100% - 120px)" : "670px"};

  span{
    background: #E0E0E0;
    border-radius: 2px;
    min-width: 60px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    label{
      font-size: 9px;
      font-weight: bold;
    }
  }

`

export const PreviewPaginate = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  //background-color: purple;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  label{
    font-size: 8px;
  }

`

export const AbasOptions = styled.div`
    display: flex;
    //flex: 1;
    gap: 10px;
    //background-color: yellow;
    height: 100%;
    align-items: center;
`

export const AbaItem = styled.label`
    display: flex;
    background-color: ${props => props.active ? props.backgroundColor : "transparent"};
    max-width: 100px;
    font-size: 16px !important;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    border-bottom: 2px solid ${props => props.active ? props.borderColor : "transparent"};
    padding: 0px 10px;
    z-index: 999;
    min-width: 120px;

    span{
        display: flex;
        //background-color: #f1f1f1;
        min-width: 24px !important; 
        min-height: 24px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 11px;
        margin-right: 5px;
        border: 1px solid #222;

    }
`

export const BoxSlider = styled.div`
    display: flex;
    //background-color: red;
    height: 100%;
    padding: 0px 0px;
    
`