import React from 'react'
import { useEffect } from 'react';
import { BoxFixxed } from '../Header/styled';

const BarPlus = () => {

    useEffect(function () {
        try {
            let toolbarTimeout = setInterval(() => {
                let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;

                let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

                if (token) {
                    window?.empreenderPlusApiInstancia({
                        caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub", //OBRIGATORIO
                        afiliado: "", //OPCIONAL
                        insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
                        app: "SUPERFAV", //code do app - OBRIGATORIO
                        headers: {
                            Authorization: tokenType + " " + token,
                            "Content-Type": "application/json",
                        },
                    });

                    clearInterval(toolbarTimeout);
                }
            }, 250);
        } catch (e) {
            console.error("empreenderPlusApiInstancia - error", e);
        }
    }, []);

    return (

        <BoxFixxed>
            <div className="insert-toolbar-plus" />
        </BoxFixxed>

    )
}

export default BarPlus