import styled from "styled-components";


export const Container = styled.div`
    //background-color: yellow ;
    display: flex ;
    width: 100% ;
    flex-direction: column ;
    padding: 0px 20px;
    gap: 10px;
`

export const ButtonHelp = styled.div`
    background-color: #121212 ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`


export const BoxFont = styled.div`
    display: flex ;
    background-color: #fff ;
    gap: 5px;
    flex-direction: column ;
    padding: 10px ;
    border-radius: 8px;


    label{
        font-size: 10px ;
        font-weight: 600;
        color: #878787;
    }

    div{
        display: flex ;
        gap: 10px;
    }
`
export const SelectCustom = styled.select`
    display: flex ;
    //background-color: red;
    height: 38px;
    font-size: 12px ;
    cursor: pointer;
    border: 1px solid #D3DAE3 ;
    border-radius: 4px ;
    padding-left: 10px ;
    
`


export const BoxTitle = styled.div`
    display: flex ;
    background-color: #fff ;
    gap: 5px;
    flex-direction: column ;
    padding: 10px ;
    border-radius: 8px;


    label{
        font-size: 10px ;
        font-weight: 600;
        color: #878787;
    }

    div{
        display: flex ;
        gap: 10px;
    }
`
export const BarConfigTitle = styled.div`
    display: flex ;
    background-color:  #F7F7F7 ;
    position: absolute;
    width: 100% ;
    height: 38px;
    align-items: center ;
    padding: 10px ;
    gap: 6px !important;
    flex-wrap: wrap ;
    justify-content: space-between;

    select{
        width: 46px ;
        font-size: 10px ;
        min-height: 20px ;
        cursor: pointer;
    }



    input[type=color] {
        width: 20px;
        height: 20px; 
        border-radius: 5px;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        border: none;
        cursor: pointer;
        font-size: 11px ;   
        border: 1px solid #f1f1f144;
    }

    input[type=color]::-webkit-color-swatch {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

    input[type=color]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

`

export const ItemBar = styled.div`
    display: flex ;
    border: ${props => props.active ? "1px solid #000000" : "none"}  ;

    img{
        cursor: pointer;
        width: ${props => props.active ? "18px" : "20px"} ;
        height: ${props => props.active ? "18px" : "20px"} ;
    }
`

export const ItemColorCustom = styled.div`
    display: flex ;
    background-color: #fff ;
    width: 22px ;
    height: 22px ;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
    gap: 2px !important;
    border: 1px solid #f1f1f1 ;
    padding: 0px !important ;
    cursor: pointer;

    div{
        display: flex ;
        min-width: 14px ;
        min-height: 2px ;
        background-color: ${props => props.color} ;
    }

    input{
        visibility: hidden ;
        position: absolute ;
    }

    label{
        //background-color: red ;
        display: flex ;
        height: 16px ;
        font-size: 14px ;
        margin-top: -4px ;
        cursor: pointer;
    }
`

export const BoxColor = styled.div`
    display: flex ;
    min-width: 20px ;
    min-height: 20px ;
    background-color: red ;
    border-radius: 2px ;
`
export const TextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #Fafafa;
    border-radius: 5px;
    padding: 50px 10px 10px 10px;
    resize: none;
    font-size: 12px ;
    font-weight: 400;
    color: #353535;
    position: relative ;
`
export const CounterItemsCircle = styled.div`
    display: flex;
    position: absolute ;
    width: 20px;
    height: 20px ;
    align-items: center ;
    justify-content: center ;
    //background-color: red ;
    border-radius: 50% ;
    right: 10px ;
    bottom: 10px;
    //border: 1px solid ${props => props.colorBorder};
    //background-color: #F5F3F0;
    background: conic-gradient(#F13F6C 0% ${props => props.porc}, #ffffff 0% 100%);

    label{
        display: flex ;
        font-size: 8px !important;
        height: calc(100% - 2px) ;
        width: 100%;
        background-color: white;
        justify-content: center ;
        align-items: center;
        border-radius: 50%;
        margin: 1px;
        
    }
`
export const BoxScroll = styled.div`
    display: flex ;
    background-color: #fff ;
    gap: 5px;
    flex-direction: column ;
    padding: 10px ;
    border-radius: 8px;


    label{
        font-size: 10px ;
        font-weight: 600;
        color: #878787;
    }

    div{
        display: flex ;
        gap: 10px;
    }
`

export const BoxWidget = styled.div`
    display: flex ;
    background-color: #fff ;
    gap: 5px;
    flex-direction: column ;
    padding: 10px ;
    border-radius: 8px;


    label{
        font-size: 10px ;
        font-weight: 600;
        color: #878787;
    }

    div{
        display: flex ;
        gap: 10px;
    }
`


export const ItemColor = styled.div`
    height: 30px ;
    display: flex ;
    align-items: center ;
    gap: 20px;
    //padding: 10px ;
    justify-content: space-between ;
    //background-color: red ;

    label{
        font-size: 11px !important;
        color: #050505 ;
        font-weight: initial;
        background-color: #F6F6F6;
        padding: 2px 5px;
        border-radius: 2px;
        //background-color: red;
    }

    span{
        display: flex ;
        //background-color: yellow ;
        width: 120px;
        height: 30px ;
        display: flex ;
        align-items: center ;
        border: 1px solid #D3DAE3;
        border-radius: 6px;
        padding: 0px 10px ;
        gap: 10px;

        div{
            width: 20px ;
            height: 20px ;
            background-color: black ;
            border-radius: 4px ;
        }

    input[type=color] {
        width: 20px;
        height: 20px; 
        border-radius: 5px;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        border: none;
        cursor: pointer;
        font-size: 11px ;   
        border: 1px solid #f1f1f144;
    }

    input[type=color]::-webkit-color-swatch {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

    input[type=color]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }
    }
`

