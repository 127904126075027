import React, { useEffect, useState } from 'react'
import { BtnAddCart, Container, Icon, Name, Price, SpaceControl } from './styled'
import { useSelector } from 'react-redux'
import { AiFillGift, AiFillHeart } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { getTranslation } from '../../../../translations'

const CardSlider01 = (props) => {

    const config = useSelector(state => state.reducerControlConfig)
    const [houver, setHouver] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        console.log("config image", config.widget_card_image_size.split("x"))
    }, [config])


    const getIcon = () => {
        switch (config.list_type_icon) {
            case "heart":
                return <AiFillHeart color={config.list_color_icon} size={12} />
            case "gift":
                return <AiFillGift color={config.list_color_icon} size={12} />
            case "trash":
                return <FaTrash color={config.list_color_icon} size={10} />
            default:
                return <FaTrash color={config.list_color_icon} size={10} />
        }
    }

    return (
        <Container width={config.widget_card_image_size.split("x")[0]} height={config.widget_card_image_size.split("x")[1]} onMouseOver={() => { setHouver(true) }} onMouseOut={() => { setHouver(false) }} >
            <img src={props.data.url} />
            {/* <SpaceControl /> */}
            {/* <Price color={config.color_price} font={config?.font} >
                R$ {props.data.price}
            </Price> */}

            {config.widget_card_name_visible == 1 &&
                <>
                    <SpaceControl />
                    <Name color={config.color_name} font={config?.font} >
                        {props.data.name}
                    </Name>
                </>
            }

            <Icon visible={houver} background={config.list_color_background_icon} >
                {getIcon()}
            </Icon>

            {config.visible_add_cart == 1 &&
                <BtnAddCart color={config?.widget_color_card_button_text} background={config?.widget_color_card_button_background} border={config?.widget_color_card_button_border}>
                     {translation.thema.barraListaFavoritos.addCarrinho}
                </BtnAddCart>}
        </Container>
    )
}

export default CardSlider01