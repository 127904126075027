import {
  FormCustom,
  InputCustom,
  ButtonSave,
  ErrorMessage,
  ButtonDelete,
  BoxInforInitial,
  BoxInforInitialLeft,
  BoxInforInitialRight,
  BoxDeleteAccount,
  SelectCustom,
} from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import * as Yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  displayScript,
  isVisibleScript,
} from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Confirmation from "../../../../components/Modal-Confirmation";
import { useState } from "react";
import { getTranslation } from "../../../../translations";

const Whatsapp = (props) => (
  <InputMask {...props} mask="(99) 9.9999-9999" maskChar="" />
);

const Home = () => {
  const dispatch = useDispatch();
  const statusUpdateAccount = useSelector(
    (state) => state.reducerUpdateAccount
  );

  const statusDeleteAccount = useSelector(
    (state) => state.reducerDeleteAccount
  );
  const history = useHistory();
  const [visibleModalConfirmation, setVisibleModalConfirmation] =
    useState(false);

  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );

  const translation = getTranslation(
    JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  );

  useEffect(() => {
    if (
      statusUpdateAccount.loading == false &&
      statusUpdateAccount.error == false &&
      statusUpdateAccount.data.length == 0
    ) {
    } else {
      const id = "statusUpdateAccount";
      if (statusUpdateAccount.loading) {
        toast.loading(translation.conta.aguarde, { toastId: id });
      } else {
        if (statusUpdateAccount.error)
          toast.update(id, {
            render: translation.conta.errorAtualizar,
            type: "error",
            isLoading: false,
            hideProgressBar: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        else {
          toast.update(id, {
            render: translation.conta.sucessoAtualizar,
            type: "success",
            isLoading: false,
            hideProgressBar: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          formik.resetForm();
          window.location.reload();
        }
      }
    }
    // const id = "statusUpdateAccount"
    // if (!(statusUpdateAccount.loading == false && !statusUpdateAccount.error == false && statusUpdateAccount.data.length == 0)) {
    //     if (statusUpdateAccount.loading) {
    //         toast.loading(translation.conta.aguarde, { toastId: id })
    //     } else {
    //         if (statusUpdateAccount.error) toast.update(id, { render: translation.conta.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //         else {
    //             toast.update(id, { render: translation.conta.sucessoAtualizar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //             formik.resetForm();
    //             //window.location.reload()
    //         }
    //     }
    // } else if (statusUpdateAccount.error) toast.update(id, { render: translation.conta.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
  }, [statusUpdateAccount]);

  useEffect(() => {
    if (
      !statusDeleteAccount.loading &&
      !statusDeleteAccount.error &&
      statusDeleteAccount.data.length == 0
    ) {
    } else {
      const id = "statusDeleteAccount";
      if (statusDeleteAccount.loading) {
        toast.loading(translation.conta.aguarde, { toastId: id });
      } else {
        if (statusDeleteAccount.error)
          toast.update(id, {
            render: translation.conta.errorExcluir,
            type: "error",
            isLoading: false,
            hideProgressBar: false,
            autoClose: 3000,
            closeOnClick: true,
          });
        else {
          toast.update(id, {
            render: translation.conta.sucessoExcluido,
            type: "success",
            isLoading: false,
            hideProgressBar: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          history.push("/login");
        }
      }
    }
  }, [statusDeleteAccount]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: JSON.parse(localStorage.getItem("depoimentos@login"))?.user.name,
      //   email: JSON.parse(localStorage.getItem("depoimentos@login"))?.user.email,
      whatsapp: JSON.parse(localStorage.getItem("depoimentos@login"))?.user
        .whatsapp,
      //   password: "",
      //   confirmationPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(translation.conta.formik.name.validacao1)
        .max(200, translation.conta.formik.name.validacao2)
        .min(3, translation.conta.formik.name.validacao1),
      //   email: Yup.string()
      //     .required(translation.conta.formik.email.validacao1)
      //     .email(translation.conta.formik.email.validacao2)
      //     .max(200, translation.conta.formik.email.validacao3)
      //     .min(3, translation.conta.formik.email.validacao4),
      whatsapp: Yup.string()
        .required(translation.conta.formik.whatsapp.validacao1)
        .max(16, translation.conta.formik.whatsapp.validacao2),
      //   password: Yup.string()
      //     .min(8, translation.conta.formik.password.validacao1)
      //     .max(200, translation.conta.formik.password.validacao2),
      //   confirmationPassword: Yup.string().oneOf(
      //     [Yup.ref("password"), null],
      //     translation.conta.formik.confirmationPassword.validacao1
      //   ),
    }),
    onSubmit: (values) => {
      delete values.confirmationPassword;
      values.language = language;
      if (values.password === "") delete values.password;
      dispatch({
        type: "SAGA_UPDATE_ACCOUNT",
        payload: {
          ...values,
          whatsapp: values.whatsapp.replace(/[^\d]+/g, ""),
        },
      });
    },
  });

  const deleteAccount = () => {
    dispatch({
      type: "SAGA_DELETE_ACCOUNT",
      payload: {
        active: false,
      },
    });
  };

  return (
    <Body>
      <SideBar />
      <div
        style={{
          margin: "0px",
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          flex: "1",
        }}
      >
        <Header />
        <Contents>
          <Confirmation
            confirmation={deleteAccount}
            visible={visibleModalConfirmation}
            setVisibleModal={setVisibleModalConfirmation}
            title={translation.conta.excluirConta}
            infor={translation.conta.InfoExcluirConta}
          />

          {/* <BoxInforInitial>
                        <BoxInforInitialLeft>
                            <label>Minha Conta</label>
                            <label>Para que os cupons criados no Lily funcionem corretamente, é necessário que o mesmo cupom exista na sua loja. Configure na sua plataforma (ex: Nuvemshop, Shopify, Yampi...).</label>
                        </BoxInforInitialLeft>

                    </BoxInforInitial> */}

          <FormCustom
            style={{ marginTop: "0px" }}
            onSubmit={formik.handleSubmit}
          >
            <InputCustom>
              <label>{translation.conta.nome}</label>
              <input
                type={"text"}
                name={"name"}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </InputCustom>
            {formik.errors.name && (
              <ErrorMessage>{formik.errors.name}</ErrorMessage>
            )}

            {/* <InputCustom>
                            <label>{translation.conta.email}</label>
                            <input type={"text"} name={"email"} value={formik.values.email} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.email && <ErrorMessage>{formik.errors.email}</ErrorMessage>} */}

            <InputCustom>
              <label>Whatsapp</label>
              <Whatsapp
                type={"text"}
                placeholder={"(00) 0.0000-0000"}
                name={"whatsapp"}
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
              />
            </InputCustom>
            {formik.errors.whatsapp && (
              <ErrorMessage>{formik.errors.whatsapp}</ErrorMessage>
            )}

            <InputCustom>
              <label>Idioma</label>
              <SelectCustom
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
              >
                <option
                  value={"pt-br"}
                  selected={language == "pt-br" ? true : false}
                >
                  Português
                </option>
                <option value={"es"} selected={language == "es" ? true : false}>
                  Espanhol
                </option>
              </SelectCustom>
            </InputCustom>

            {formik.errors.whatsapp && (
              <ErrorMessage>{formik.errors.whatsapp}</ErrorMessage>
            )}

            {/* <InputCustom>
                            <label>{translation.conta.nome}</label>
                            <input type={"password"} placeholder={translation.conta.infoSenha} name={"password"} value={formik.values.password} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.password && formik.touched.password && <ErrorMessage>{formik.errors.password}</ErrorMessage>}

                        <InputCustom>
                            <label>{translation.conta.confirmSenha}</label>
                            <input type={"password"} placeholder={translation.conta.confirmSenha} name={"confirmationPassword"} value={formik.values.confirmationPassword} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.confirmationPassword && formik.touched.confirmationPassword && <ErrorMessage>{formik.errors.confirmationPassword}</ErrorMessage>} */}

            <ButtonSave type="submit">{translation.conta.salvar}</ButtonSave>
          </FormCustom>

          <BoxDeleteAccount>
            <label style={{ marginTop: "50px", marginBottom: "10px" }}>
              <strong>{translation.conta.excluir}:</strong>{" "}
              {translation.conta.alertaExcluir}
            </label>
            <ButtonDelete
              type="button"
              onClick={() => setVisibleModalConfirmation(true)}
            >
              {translation.conta.excluir}
            </ButtonDelete>
          </BoxDeleteAccount>
        </Contents>
      </div>
      {isVisibleScript(false)}
    </Body>
  );
};

export default Home;
