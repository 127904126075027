
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";



export function* listNotify(action) {
    //console.log("teste")
    yield put({ type: "NOTIFY_LIST_REQUEST", })
    //yield delay(200);
    const result = yield call(GET, { endpoint: `/dashboard/notifications` })
    //console.log("Result - Notify: ", result)
    if (result === false) {
        yield put({ type: "NOTIFY_LIST_ERROR" })
    } else {
        yield put({ type: "NOTIFY_LIST_SUCCESS", payload: result.data })
    }
}

export function* getInforAccount(action) {
    //console.log("teste")
    yield put({ type: "GET_INFOR_ACCOUNT_REQUEST", })
    //yield delay(200);
    const result = yield call(GET, { endpoint: `/users/${action.payload.id}` })
    //console.log("Result - Notify: ", result)
    if (result === false) {
        yield put({ type: "GET_INFOR_ACCOUNT_ERROR" })
    } else {
        yield put({ type: "GET_INFOR_ACCOUNT_SUCCESS", payload: result.data })
    }
}
