import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: ${props => props.background};
    //min-height: 400px;
    flex-direction: column;
    width: 100%;
    //background-color: yellow;
    height: max-content;
`

export const AbasOptions = styled.div`
    display: flex;
    //flex: 1;
    gap: 10px;
    //background-color: yellow;
    height: 100%;
    align-items: center;
`

export const AbaItem = styled.label`
    display: flex;
    background-color: ${props => props.active ? props.backgroundColor : "transparent"};
    max-width: 100px;
    font-size: 16px !important;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    border-bottom: 2px solid ${props => props.active ? props.borderColor : "transparent"};
    padding: 0px 10px;
    z-index: 999;
    min-width: 115px;

    span{
        display: flex;
        //background-color: #f1f1f1;
        min-width: 24px !important; 
        min-height: 24px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 11px;
        margin-right: 5px;
        border: 1px solid #222;

    }
`

export const Divider = styled.div`
    display: flex;
    //background-color: #dadada;
    background-color: ${props => props.background};
    min-height: 2px;
    width: calc(100% - 40px);
    margin: 0px 20px;
    margin-top: -17px;
`

export const Title = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
    padding: 0px 20px;
    gap: 20px;
    justify-content: space-between;

`

export const BoxSlider = styled.div`
    display: flex;
    //background-color: red;
    height: 100%;
    padding: 0px 0px;
    
`

export const NavBox = styled.div`
    display: flex;
    gap: 10px;

    svg{
        //color: #dadada;
        color: ${props => props.color};
        cursor: pointer;

        :hover{
            //color: #000000
            color: ${props => props.colorHover};
        }
    }
`