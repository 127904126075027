import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import Card from '../../components/Card/index'
import { Container, ContainerCards } from './styled'
import { isVisibleScript } from "../../../../utils/displayScript";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTranslation } from "../../../../translations";
import AlertCloseBoss from "../../../../components/alert-close-boss copy";
import ModalInforVideo from "../../../../components/modal-infor-video";

const Home = () => {

    // useEffect(() => {
    //     const script2 = document.createElement("script")
    //     script2.type = "text/javascript"
    //     script2.src = "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js";
    //     script2.defer = "defer"
    //     document.body.appendChild(script2);
    // }, [])

    const [query, setQuery] = useState("")
    const queryHelp = useSelector(state => state.reducerQueryHelp)
    const dispatch = useDispatch()
    const [visibleModalInfor, setVisibleModalInfor] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        setQuery(queryHelp)
        dispatch({ type: "SET_QUERY_HELP", payload: "" })
    }, [])


    return (
        <Body>
            <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={"https://www.youtube.com/embed/EIVuxL8G2go"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1", minWidth: '0%' }} >
                <Header />
                <Contents>

                    <AlertCloseBoss close={true} margin={"0px 0px 30px 0px"} visible={true} >
                        <label style={{ alignItems: "center", margin: "0px" }} >
                        {translation.alerts.ajuda}
                        </label>
                        <span style={{}}>
                            <label onClick={() => { setVisibleModalInfor(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                {translation.alerts.verComoFunciona}
                            </label>
                        </span>
                    </AlertCloseBoss>
                    <Container>
                        <iframe
                            src={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? `https://superfavoritos-latam.sak.com.br/faq?q=${query}` : `https://superfavoritos.sak.com.br/faq?q=${query}`}
                            //src={`https://superfavoritos.sak.com.br/faq?q=${query}`}
                            title="super-favoritos-faq" style={{ border: 'none', width: '100%', height: '120vh' }} frameBorder={0}>                        </iframe>
                        <ContainerCards>
                            <Card background="#20D489"
                                color="white"
                                headerText={translation.ajuda.atendimento}
                                mainText={translation.ajuda.suporteWhatsapp}
                                btnText={translation.ajuda.whatsapp}
                                btnAction="javascript:sak_toggle_widget()" />
                            <Card background="#ffcc00"
                                color="#5e0a07"
                                headerText={translation.ajuda.canais}
                                mainText={translation.ajuda.suporteForum}
                                btnText={translation.ajuda.forum}
                                btnAction="https://forum.empreender.com.br/t/geral" />
                        </ContainerCards>
                    </Container>
                </Contents>
            </div>
            {isVisibleScript(true)}
        </Body>
    );
}

export default Home